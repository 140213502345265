<template>
  <div class="department_tree">
    <div class="part_tree">
      <el-tree :data="treeData" :props="{ label: 'name' }" node-key="id" @node-click="handleNodeClick" :expand-on-click-node="false" highlight-current></el-tree>
    </div>
    <div class="part_people" v-if="people">
      <div style="margin-bottom: 6px">
        <el-input v-model="peopleSearchValue" placeholder="搜索" size="mini"></el-input>
      </div>
      <div v-if="radio">
        <el-radio-group v-model="checkedPeople" @change="handleRadioChange">
          <el-radio v-for="people in peopleList" :key="people.id" :label="people.id">{{ people.label }}</el-radio>
        </el-radio-group>
      </div>
      <div v-else>
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="checkedPeople" @change="handleCheckedChange">
          <el-checkbox v-for="people in peopleList" :label="people.id" :key="people.id">{{people.label}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="selected_people" v-if="people">
      <p>已选人员</p>
      <ul>
        <li v-for="item in checkedPeopleDisplay" :key="item.id">
          <em>{{ item.label }}</em>
          <span v-if="!radio" @click="handleDelete(item.id)"><i class="el-icon-delete"></i></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
    reqAdminDepartmentTree,
    reqDepartmentTree,
    reqDepartmentPeople,
} from '@/api/common/common'
export default {
    /* 部门|组织树 */
    name: 'DepartmentTree',

    model: {
        prop: 'departmentData',
        event: 'change',
    },

    props: {
        departmentData: {
            type: Object,
            default: () => ({}),
        },

        /* 获取哪个类型的部门树  admin 表示平台层级， tenement 表示租户级 */
        type: {
            type: String,
            default: 'tenement',
        },
        /* 是否单选 */
        radio: {
            type: Boolean,
            default: false,
        },
        people: {
            type: Boolean,
            default: true,
        },
    },

    data () {
        return {
            treeData: [],
            originPeopleList: [],
            peopleList: [],
            /* 当前选择的部门 */
            selectedDep: {},
            checkedPeople: [],
            peopleSearchValue: '',
            checkedPeopleDisplay: [],
            checkAll: false,
        }
    },

    created () {
        this.getDepartmentTree()
    },

    watch: {
        departmentData (v) {
            if (v && v.people && v.people.length) {
                this.checkedPeopleDisplay = this.departmentData.people
            }
        },

        peopleSearchValue (str) {
            const { originPeopleList } = this
            if (str) {
                this.peopleList = originPeopleList.filter((v) => v.label.includes(str))
            } else {
                this.peopleList = originPeopleList
            }
        },
    },

    methods: {
    /* 清空用户操作痕迹 */
        reset () {
            this.peopleList = []
            this.checkedPeople = []
            this.checkedPeopleDisplay = []
            this.checkAll = false
        },

        getDepartmentTree () {
            if (this.type === 'tenement') {
                reqDepartmentTree().then(({ data }) => {
                    this.treeData = [data]
                })
            } else {
                reqAdminDepartmentTree().then(({ data }) => {
                    this.treeData = data
                })
            }
        },

        /* 触发回调 */
        handleCallback () {
            const { checkedPeopleDisplay, selectedDep } = this
            this.$emit('change', {
                department: [selectedDep],
                people: checkedPeopleDisplay,
            })
        },

        /* 点击树节点 */
        handleNodeClick (node) {
            this.selectedDep = { id: node.id, label: node.name }
            if (this.people) {
                reqDepartmentPeople({ id: node.id, size: 1000 }).then(({ data }) => {
                    if (data.records && data.records.length) {
                        const d = data.records.map((v) => ({
                            id: v.userId,
                            label: v.name,
                        }))
                        this.originPeopleList = d.map((item) => ({ ...item }))
                        this.peopleList = d

                        let count = 0
                        const len = this.peopleList.length

                        for (let i = 0; i < len; i++) {
                            this.checkedPeopleDisplay.map((v) => {
                                if (this.peopleList[i].id === v.id) {
                                    count++
                                    this.checkedPeople.push(v.id)
                                }
                            })
                        }
                        this.checkAll = count === len
                    }
                })
            } else {
                this.$emit('change', { department: [this.selectedDep] })
            }
        },

        handleCheckAllChange (val) {
            if (val) {
                const peopleIds = this.peopleList.map((v) => v.id)
                this.checkedPeople = peopleIds
                this.findChecked(peopleIds)
            } else {
                this.checkedPeople = []
            }
        },

        /* 判断是否将当前选中的人加入已选中的人中，防止重复 */
        filterChecked (currentArr) {
            const { checkedPeopleDisplay, radio } = this
            if (radio) {
                this.checkedPeopleDisplay = currentArr
            } else {
                const tempArr = []
                currentArr.forEach((people) => {
                    if (!checkedPeopleDisplay.some((v) => v.id === people.id)) {
                        tempArr.push(people)
                    }
                })
                this.checkedPeopleDisplay.push(...tempArr)
            }
            this.handleCallback()
        },

        /* 获取当前选中的人对象 */
        findChecked (ids) {
            const { peopleList } = this
            const arr = []
            ids.forEach((id) => {
                const val = peopleList.find((v) => v.id === id)
                if (val) arr.push(val)
            })
            this.filterChecked(arr)
            this.checkAll = this.peopleList.length === this.checkedPeople.length
        },

        /* 单选 */
        handleRadioChange (value) {
            this.findChecked([value])
        },

        /* 多选 */
        handleCheckedChange (value) {
            this.findChecked(value)
        },

        handleDelete (id) {
            const { checkedPeopleDisplay } = this
            this.checkedPeopleDisplay = checkedPeopleDisplay.filter(
                (v) => v.id !== id
            )
            this.handleCallback()
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/styles/variable.scss";
.department_tree {
  display: flex;
  min-height: 300px;
  .part_tree {
    padding: 6px;
    width: 200px;
    min-height: 200px;
    border: 1px solid $c3;
  }
  .part_people {
    min-height: 200px;
    padding: 6px;
    width: 200px;
    border-top: 1px solid $c3;
    border-bottom: 1px solid $c3;
  }
  .selected_people {
    min-height: 200px;
    padding: 6px;
    width: 200px;
    border: 1px solid $c3;
    font-size: 14px;
    p {
      margin: 5px 0;
      padding-bottom: 5px;
      border-bottom: 1px solid $c3;
    }
    li {
      margin: 8px 0;
    }
    span {
      margin-left: 5px;
      padding: 3px 5px;
      background-color: $c7;
      color: $c10;
      cursor: pointer;
    }
  }
}
</style>

import request from '@/api/request'

// 用户消息列表
export function reqNewsList (params) { return request({ url: '/usermessage/page', params: params }) }

export function reqListCount (params) { return request({ url: '/usermessage/unread/count', params: params }) }
// 标记消息为已读
export function reqNewsReadOne (params) { return request({ url: '/usermessage/mark', params: params, method: 'PUT' }) }

// 标记全部消息已读
export function reqNewsRead (params) { return request({ url: '/usermessage/allmark', params: params, method: 'PUT' }) }

// 通过Id查询消息
export function reqNewsSearch (params) { return request({ url: '/usermessage/:id', params: params }) }

// 删除消息通过Id
export function reqNewsDel (params) { return request({ url: '/usermessage/batch', params, method: 'delete' }) }

// 消息接收列表
export function reqNewsAcceptList (params) { return request({ url: '/messageorder', params: params }) }

// 消息确定接收
export function reqNewsAccept (id, params) { return request({ url: `/messageorder/${id}`, params: params, method: 'post' }) }

// 消息取消接收
export function reqNewsCancel (userId, messageType) { return request({ url: '/messageorder/:userId/:messageType', params: { userId, messageType }, method: 'delete' }) }

// 订阅消息渠道
export function reqNewsOrderType (userId, channel, params) { return request({ url: `/messageorder/channel/${userId}/${channel}`, params: params, method: 'post' }) }

// 取消订阅消息渠道
export function reqNewsOrderTypeCancel (userId, messageType, channel) { return request({ url: `/messageorder/channel/${userId}/${messageType}/${channel}`, params: { userId, messageType, channel }, method: 'delete' }) }

export function reqDingList (params) { return request({ url: '/applicationmessagetype', params: params }) }

export function reqRoleDing (params) { return request({ url: '/messageroleorder', params: params, method: 'post' }) }

export function reqRoleDingSearch (messageType) { return request({ url: '/messageroleorder/:messageType', params: { messageType } }) }

<template>
  <div class="allsNews">
    <el-container>
      <el-aside id="aside">
        <el-tree
        :data="data1"
        :props="defaultProps"
         node-key="id"
         @node-click="handleNodeClick"
         :default-expanded-keys="[0, 1]"
        :default-checked-keys="[5]"
        style="100%">
         </el-tree>
      </el-aside>
      <el-main>
          <!-- 接收管理 -->
          <div v-if="biaoji1==1" class="basicReception">
            <header> 基本接收管理</header>
            <el-table id="table" default-expand-all :row-style="{background: '#f0f9eb'}" :data="newsList2">
                <el-table-column type="expand">
                    <template v-slot='scope'>
                      <el-table :show-header="false" :data="scope.row.dictMessageTypes">
                        <el-table-column property="mean" label="消息类型"></el-table-column>
                         <el-table-column  label="站内信">
                          <template v-slot='scope'>
                            <el-checkbox :disabled="scope.row.dab" :key="scope.row.val" v-model="scope.row.checked" @change="changeone(scope.row)"></el-checkbox>
                            接收
                          </template>
                        </el-table-column>
                        <el-table-column  label="短信">
                          <template v-slot='scope'>
                            <el-checkbox :disabled="!scope.row.checked" :key="scope.row.val" v-model="scope.row.smsChecked" @change="changeOrderType(scope.row, 'sms')"></el-checkbox>
                            短信
                          </template>
                        </el-table-column>
                        <el-table-column  label="邮件">
                          <template v-slot='scope'>
                            <el-checkbox :disabled="!scope.row.checked" :key="scope.row.val" v-model="scope.row.emailChecked" @change="changeOrderType(scope.row, 'email')"></el-checkbox>
                            邮件
                          </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                          <template v-slot="scope">
                                <el-button v-show="disp" type="text" size="small" @click="choosePerson(scope.row)">设置消息接收人</el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                </el-table-column>
                <el-table-column property="appName" label="消息类型"></el-table-column>
                <el-table-column  label="站内信"></el-table-column>
                <el-table-column  label="短信"></el-table-column>
                <el-table-column  label="邮件"></el-table-column>
                <el-table-column  label="操作"></el-table-column>
            </el-table>
          </div>
          <!-- 全部消息 -->
           <div v-else-if="biaoji1==0" class="allNews">
            <!-- <header>全部消息</header> -->
             <el-tabs style="margin-top:30px" v-model="activeName" type="card" @tab-click="handleTabsChange">
               <el-tab-pane :label="item.appName" :name="item.appCode" v-for="item in newsList2" :key="item.appCode">
                <div class="tabs" >
              <span class="tabs-item" @click="handleClick(item.val)" :class="selectIndex==item.val?'clicked':''" v-for="item in allNews" :key="item.val">{{item.mean}}</span>
            </div>
               </el-tab-pane>
            </el-tabs>
              <TableWrap @callback="handlePagination" :total="total" v-loading="loading">
                <el-table :data="tableData" border  @selection-change="handleSelectionChange">
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column prop="readStatus" label="状态" width="120" align="center">
                    <template v-slot="scope">
                          <span v-if="scope.row.readStatus=== 0"><span class="unread"></span>未读</span>
                          <span v-if="scope.row.readStatus=== 1">已读</span>
                    </template>
                  </el-table-column>
                  <el-table-column  label="消息标题" width="620">
                    <template v-slot="scope">
                          <p @click="toLookNews(scope.row, scope.row.id)" style="text-decoration:underline; cursor: pointer;">{{ scope.row.title }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="publishTime" label="推送日期" align="center">
                  </el-table-column>
                  <el-table-column prop="readTime" label="阅读日期" align="center">
                  </el-table-column>
                </el-table>
              </TableWrap>
                <div class="option-list">
                  <button class="option-item" @click="littlDel">删除</button>
                  <button class="option-item" @click="littleRead">标记已读</button>
                  <button class="option-item" @click="allRead">全部已读</button>
                  <!-- <button class="option-item" disabled>全部删除</button> -->
              </div>
          </div>
          <news-detail :leixing="type" :toDetailId='toDetailId' :detail="detailObj"  @changeBiaoJi='change' v-else></news-detail>
      </el-main>
      <el-dialog
        title="设置接收人"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="close">
           <el-form ref="form" :model="formData"  label-width="80px">
              <el-form-item label="消息类型">
                    <el-input size="small" v-model="name" disabled></el-input>
              </el-form-item>
              <el-form-item label="角色">
                    <el-checkbox-group v-model="roleIds">
                        <el-checkbox v-for="item in allRoles" :label="item.id" :key="item.id">{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
              </el-form-item>
           </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="save">保 存</el-button>
        </span>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import tableMixIn from '@/mixIn/tableMixIn'
import { mapState } from 'vuex'
import NewsDetail from './newsDetail'
import { reqRoles } from '@/api/system/role'
import Cookies from 'js-cookie'
// reqNewsAccept, reqNewsCancel
import {
    reqListCount,
    reqNewsList,
    reqNewsAcceptList,
    reqNewsAccept,
    reqNewsCancel,
    reqNewsRead,
    reqNewsReadOne,
    reqNewsDel,
    reqDingList,
    reqRoleDing,
    reqRoleDingSearch,
    reqNewsOrderType,
    reqNewsOrderTypeCancel
} from '@/api/newsCenter/newsCenter.js'
export default {
    name: 'allsNews',
    mixins: [tableMixIn],
    components: {
        NewsDetail
    },
    props: {
        obj: {
            type: Object
        },
        id: {
            type: Number
        },
        newlist: {
            type: Array
        },
        biaoji: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            data1: [{
                id: 0,
                label: '消息中心',
                children: [{
                    id: 5,
                    label: '全部消息',
                }, {
                    id: 6,
                    label: '未读消息',
                }, {
                    id: 7,
                    label: '已读消息',
                }]
            }, {
                id: 1,
                label: '消息接收管理',
                children: [{
                    id: 8,
                    label: '基本接收管理',

                }]
            }],
            // biaoji: this.biaoji, // 0 是全部消息  1  是消息接收  2  是消息详情
            // toDetailId: '', // 去详情页传的id
            // reqTableData: reqNewsList,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            selectIndex: 0,
            selectIndexOne: 0,
            loading: true,
            allNews: [],
            // 接收消息数据// 所有的type类型
            dingYueList: [], // 被订阅的类型
            newsList2: [], // 返回的类型数据表
            userId: '',
            tableData: [],
            type: 1, // 当前tab所选消息类型 默认tab为第一个的数据
            status: '', // 当前消息的阅读状态
            detailObj: {}, // 详情对象
            ids: [], // 根据多选框选出的条数据行中的ID
            current: 1,
            size: 20,
            total: 50,
            toDetailId: 0,
            biaoji1: this.biaoji,
            newsList3: [],
            allRoles: [],
            dialogVisible: false,
            formData: {
                roleIds: [],
            },
            roleIds: [],
            name: '',
            disp: true,
            dab: false,
            activeName: '',
            appCode: '',
            msgType: 0,
            isGetCount: false,
        }
    },
    watch: {
        obj (val) {
            if (!val) {
            // this.biaoji = 0
                this.biaoji1 = 0
                // 默认请求系统消息
                // this.getTableData(1)
            } else {
                window.sessionStorage.setItem('newsList', JSON.stringify(this.newlist))
                // this.biaoji = this.biaoji
                this.detailObj = this.obj
                this.biaoji1 = this.biaoji
                this.toDetailId = this.id
            }
        }
    },
    created () {
        // 默认显示tab为第一个的数据
        // 获取当前登录用户的id
        this.userId = ''
        const leixing = this.$store.state.user.source
        if (leixing === 'tenant') {
            this.userId = this.$store.state.user.sysUser.userId
        } else {
            this.userId = this.$store.state.user.sysUser.userId
        }
        this.getUnReadNewsCount()

        // if (!this.$route.params.obj) {
        //     this.biaoji = 0
        //     // 默认请求系统消息
        //     this.getTableData(1)
        // } else {
        //     window.sessionStorage.setItem('newsList', JSON.stringify(this.$route.params.list))
        //     this.biaoji = this.$route.params.biaoji
        //     this.detailObj = this.$route.params.obj
        //     this.toDetailId = this.$route.params.id
        // }
        // 消息接收默认接收的数据

        reqDingList().then((res) => {
            this.newsList3 = res.data
            this.getAcceptList()
        })
        // this.getTableData(this.msgType)
        if (Cookies.get('authTYPE') === 'false') {
            this.disp = false
        }
        // if (!this.obj) {
        //     // this.biaoji = 0
        //     this.biaoji1 = 0
        //     // 默认请求系统消息
        //     this.getTableData(1)
        // } else {
        //     window.sessionStorage.setItem('newsList', JSON.stringify(this.newlist))
        //     // this.biaoji = this.biaoji
        //     this.detailObj = this.obj
        //     this.biaoji1 = this.biaoji
        //     this.toDetailId = this.id
        // }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        // 取消订阅和订阅
        changeone (e) {
            // console.log(e)
            if (e.checked) {
                // 订阅
                let selectType = []
                selectType.push(Number(e.val))
                reqNewsAccept(this.userId, selectType).then((res) => {
                    if (res.code === 0) {
                        this.$message('接收成功')
                        selectType = []
                    }
                })
            } else {
                // 取消订阅
                const messageType = Number(e.val)
                const userId = this.userId
                reqNewsCancel(userId, messageType).then((res) => {
                    if (res.code === 0) {
                        this.$message('取消接收成功')
                    } else if (res.code === 1) {
                        this.getAcceptList()
                    }
                })
            }
        },
        // 短信、电子邮件订阅操作
        changeOrderType (e, type) {
            // console.log(e + '>>>>' + type)
            // console.log(e.checked)
            if ('checked' in e) {
                if (type === 'sms') {
                    const channel = 'sms'
                    if (e.smsChecked) {
                        let messageTypes = []
                        messageTypes.push(Number(e.val))
                        reqNewsOrderType(this.userId, channel, messageTypes).then((res) => {
                            if (res.code === 0) {
                                this.$message('短信订阅成功')
                                messageTypes = []
                            }
                        })
                    } else {
                        const messageType = Number(e.val)
                        reqNewsOrderTypeCancel(this.userId, messageType, channel).then((res) => {
                            if (res.code === 0) {
                                this.$message('短信取消订阅成功')
                            }
                        })
                    }
                } else {
                    const channel = 'email'
                    if (e.emailChecked) {
                        let messageTypes = []
                        messageTypes.push(Number(e.val))
                        reqNewsOrderType(this.userId, channel, messageTypes).then((res) => {
                            if (res.code === 0) {
                                this.$message('邮件订阅成功')
                                messageTypes = []
                            }
                        })
                    } else {
                        const messageType = Number(e.val)
                        reqNewsOrderTypeCancel(this.userId, messageType, channel).then((res) => {
                            if (res.code === 0) {
                                this.$message('邮件取消订阅成功')
                            }
                        })
                    }
                }
            } else {
                this.$message.warning('请先接受此类型的消息')
            }
        },
        handleNodeClick (data) {
            const { id } = data
            switch (id) {
            case 5:
                // 系统消息/全部消息
                // this.biaoji = 0
                this.biaoji1 = 0
                this.status = ''
                this.getTableData(this.type)
                this.isHaveCompent = false
                break
            case 6:
                // 未读消息
                // this.biaoji = 0
                this.biaoji1 = 0
                this.getTableData(this.type, 0)
                this.status = 0
                this.isHaveCompent = false
                break
            case 7:
                // 已读消息
                // this.biaoji = 0
                this.biaoji1 = 0
                this.getTableData(this.type, 1)
                this.status = 1
                this.isHaveCompent = false
                break
            case 8:
                // this.biaoji = 1
                this.biaoji1 = 1
                this.isHaveCompent = true
                break
            }
        },
        handleClick (tab) {
            this.selectIndex = tab
            this.current = 1
            this.size = 20
            this.total = 0
            this.type = Number(tab)
            this.loading = true
            this.getTableData(tab, this.status)
            // this.t = setTimeout(() => {
            //     this.getTableData(tab, this.status)
            // }, 1000)
        },
        handleTabsChange (tab) {
            this.appCode = tab.name
            this.newsList2.forEach((item) => {
                if (item.appCode === tab.name) {
                    this.allNews = item.dictMessageTypes
                    this.selectIndex = Number(item.dictMessageTypes[0].val)
                    this.getTableData(Number(item.dictMessageTypes[0].val), this.status)
                }
            })
        },
        // 查看消息
        toLookNews (item, id) {
            this.biaoji1 = 2
            this.toDetailId = id
            this.detailObj = item
        },
        // 详情传过来的
        change (item, item1) {
            this.biaoji1 = item
            this.getTableData(item1)
            this.getUnReadNewsCount()
        },
        // 获取未读消息的条数
        getUnReadNewsCount () {
            reqListCount().then((res) => {
                this.count = res.data
                const t = '未读消息' + '(' + res.data + ')'
                this.$set(this.data1[0].children[1], 'label', t)
            })
        },
        // type为消息类型 status为消息状态 点击左边全部消息未读消息已读消息都只能默认消息类型为1的消息
        getTableData (type, status) {
            const { current, size, appCode } = this
            const messageType = type
            const readStatus = status
            // 默认显示系统消息 type为1
            if (status === 0 || status === 1) {
                reqNewsList({ messageType, readStatus, current, size, appCode }).then((res) => {
                    const { data } = res
                    this.total = data.total
                    this.tableData = data.records
                    this.loading = false
                    window.sessionStorage.setItem('newsList', JSON.stringify(data.records))
                    // clearTimeout(this.t)
                })
            } else {
                reqNewsList({ messageType, current, size, appCode }).then((res) => {
                    const { data } = res
                    this.tableData = data.records
                    this.total = data.total
                    this.loading = false
                    window.sessionStorage.setItem('newsList', JSON.stringify(data.records))
                })
            }
        },
        handlePagination (data) {
            // console.log(data)
            this.current = data.currentPage
            this.size = data.pageSize
            this.getTableData(this.type, this.status)
        },
        // 标记全部已读
        allRead () {
            const userId = this.userId
            reqNewsRead({ userId }).then((res) => {
                if (res.code === 0) {
                    this.$message.success('全部消息标记已读成功')
                    this.getTableData(this.type)
                    this.getUnReadNewsCount()
                }
            })
        },
        // 标记部分消息已读
        littleRead () {
            if (this.ids.length > 0) {
                reqNewsReadOne(this.ids).then((res) => {
                    if (res.code === 0) {
                        this.$message.success('选中标记已读成功')
                        this.getTableData(this.type)
                        this.getUnReadNewsCount()
                    }
                })
            } else {
                this.$message.warning('请至少选择一条消息')
            }
        },
        // 批量删除消息
        littlDel () {
            if (this.ids.length > 0) {
                reqNewsDel(this.ids).then((res) => {
                    if (res.code === 0) {
                        this.$message.success('所选消息删除成功')
                        this.getTableData(this.type)
                    }
                })
            } else {
                this.$message.warning('请至少选择一条消息')
            }
        },
        // 选中的消息事件
        handleSelectionChange (val) {
            // this.multipleSelection = val
            this.ids = []
            val.forEach((item) => {
                this.ids.push(item.id)
            })
        },
        // 消息接收显示数据
        getAcceptList () {
            reqNewsAcceptList().then((res1) => {
                if (res1.data.length > 0) {
                    this.dingYueList = res1.data.map((item) => {
                        return {
                            messageType: item.messageType,
                            userId: item.userId,
                            creator: item.creator,
                            channels: item.channels
                        }
                    })
                    this.dingYueList.forEach((item1, index) => {
                        this.newsList3.forEach((item2, index1) => {
                            item2.dictMessageTypes.forEach((item3, index2) => {
                                if (item1.messageType === Number(item3.val)) {
                                    this.$set(this.newsList3[index1].dictMessageTypes[index2], 'checked', true)
                                    if (item1.channels.indexOf('sms') > -1) {
                                        this.$set(this.newsList3[index1].dictMessageTypes[index2], 'smsChecked', true)
                                    }
                                    if (item1.channels.indexOf('email') > -1) {
                                        this.$set(this.newsList3[index1].dictMessageTypes[index2], 'emailChecked', true)
                                    }
                                    // 站内信不是创建者不能操作
                                    if (item1.userId === item1.creator) {
                                        this.$set(this.newsList3[index1].dictMessageTypes[index2], 'dab', false)
                                    } else {
                                        this.$set(this.newsList3[index1].dictMessageTypes[index2], 'dab', true)
                                    }
                                }
                            })
                        })
                    })
                    this.newsList2 = this.newsList3
                    this.allNews = this.newsList2[0].dictMessageTypes
                    this.activeName = this.newsList2[0].appCode
                    this.appCode = this.newsList2[0].appCode
                    this.selectIndex = Number(this.newsList2[0].dictMessageTypes[0].val)
                    this.type = Number(this.newsList2[0].dictMessageTypes[0].val)
                    this.getTableData(Number(this.newsList2[0].dictMessageTypes[0].val))
                } else {
                    this.dingYueList = []
                    this.newsList3.forEach((item, index) => {
                        item.dictMessageTypes.forEach((item, index1) => {
                            return this.$set(this.newsList3[index].dictMessageTypes[index1], 'checked', false)
                        })
                    })
                    this.newsList2 = this.newsList3
                    this.allNews = this.newsList2[0].dictMessageTypes
                    this.activeName = this.newsList2[0].appCode
                    this.appCode = this.newsList2[0].appCode
                    this.selectIndex = Number(this.newsList2[0].dictMessageTypes[0].val)
                    this.type = Number(this.newsList2[0].dictMessageTypes[0].val)
                    this.getTableData(Number(this.newsList2[0].dictMessageTypes[0].val))
                }
            })
        },
        // 设置消息接收人
        choosePerson (item) {
            // console.log(item)
            this.getRoleList()
            this.name = item.mean
            this.formData.messageType = Number(item.val)
            reqRoleDingSearch(Number(item.val)).then((res) => {
                if (res.data !== null) {
                    this.formData = res.data
                    console.log(this.formData.roleIds)
                    this.roleIds = res.data.roleIds
                } else {
                    this.formData.roleIds = []
                    this.roleIds = []
                    console.log(this.formData.roleIds)
                }
            })
            this.dialogVisible = true
        },
        getRoleList () {
            reqRoles()
                .then(({ data }) => {
                    this.allRoles = data.map(v => ({ id: v.roleId, name: v.roleName }))
                })
        },
        close () {
            this.dialogVisible = false
            this.formData = {}
            this.formData.roleIds = []
        },
        save () {
            this.formData.roleIds = this.roleIds
            reqRoleDing(this.formData).then((res) => {
                if (res.code === 0) {
                    this.$message.success('设置成功')
                    this.dialogVisible = false
                    this.getAcceptList()
                    this.formData = {}
                    this.formData.roleIds = []
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
#aside{
  .el-tree{
  height: 100%;
}
}

.allsNews {
  width: 100%;
  height: 100%;
  header {
    height: 40px;
    line-height: 40px;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid rgb(215,215,215);
  }
  .tabs {
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 8px;
    .tabs-item {
      display: inline-block;
      height: 30px;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: rgb(127,127,127);
      padding: 5px 10px;
      cursor: pointer;
      background-color: rgb(215,215,215);
      margin-left: 10px;
    }
  }
  .biaoge {
    margin-top: 8px;
  }
  .option-list {
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 8px;
    button {
      display: inline-block;
      width: 100px;
      font-size: 16px;
      text-align: center;
      color: rgb(127,127,127);
      padding: 5px;
      cursor: pointer;
      background-color: rgb(242,242,242);
      margin-left: 10px;
    }
  }
}
.basicReception {
  height: 100%;
  header {
    height: 40px;
    line-height: 40px;
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid rgb(215,215,215);
  }
  .el-table__row .expanded{
background-color: #FAFAFA !important;
}
}
 .clicked {
          background-color: rgb(2,167,240) !important;
          color: white !important;
  }
  .flexDisplay .el-aside, .el-aside {
    width: 220px!important;
    height: 100%;
    background-color: #fff;
}
 .el-main {
    padding: 0 !important;
}
#table{
  overflow: visible;
}
.unread{
  color: red;
  border-radius: 50%;
  background-color: red;
  width: 5px;
  height: 5px;
  display: inline-block;
  margin-bottom: 2px;
  margin-right: 2px;
}
</style>

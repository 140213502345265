import request from '@/api/request'

/* 角色列表不分页 */
export function reqRoles (params) { return request({ url: '/role/list', params }) }

/* 获取角色列表 */
export function reqRoleList (params) { return request({ url: '/role/page', params }) }

/* 新增(post)/编辑(put)角色 */
export function reqCreateEditRole (params, method) { return request({ url: '/role', method, params }) }

/* 获取角色详情 */
export function reqRoleDetail (params) { return request({ url: '/role/:id', params }) }

/* 删除角色 */
export function reqRoleDelete (params) { return request({ url: '/role/:id', method: 'delete', params }) }

/* 获取权限 */
export function reqPermission (params) { return request({ url: '/menu/authority/:appId/:roleId', params }) }

/* 修改角色权限 */
export function reqRolePermissionEdit (params) { return request({ url: '/role/menu', method: 'put', params }) }

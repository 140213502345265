export default {
    data () {
        return {
            searchOptions: [],
            multipleSelection: [],
            loading: false,
            current: 1,
            size: 20,
            total: 50,
            query: {},
            tableData: [],
            // 请求表格数据的接口方法，请在具体的业务组件中进行赋值
            reqTableData: null
        }
    },

    methods: {
        /* 搜索回调 */
        handleSearchCallback ({ data }) {
            this.query = data
            this.current = 1
            this.getTableData()
        },

        /* 勾选表格项 */
        handleSelectionChange (array) {
            this.multipleSelection = array
        },

        /* 表格分页操作回掉 */
        handlePagination ({ pageSize, currentPage }) {
            this.size = pageSize
            this.current = currentPage
            this.getTableData()
        },

        /** 获取表格数据 */
        getTableData (callback) {
            const { query, current, size } = this
            this.loading = true
            this.reqTableData({ ...query, current, size })
                .then(({ data: { records, total } }) => {
                    this.total = total
                    this.loading = false
                    if (callback) {
                        callback(records)
                    } else {
                        this.tableData = records
                    }
                }).catch((error) => {
                    console.error('get table:', error)
                    this.loading = false
                })
        }
    }
}

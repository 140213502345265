<template>
    <div class="listBox">
        <!-- 头部搜索和按钮 -->
        <div class="cus_table_top_operate_part">
            <slot name="tablesearch"> </slot>
            <div>
                <slot name="tableOperate"> </slot>
            </div>
        </div>
        <!-- table勾选信息 -->
        <slot name="checkedInfo"></slot>
        <slot></slot>
        <!-- 分页 -->
        <div class="cus_pagination_part">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="currentTotal"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
/**
 * 需要绑定：
 * 1. total(总数据条数)
 * 2. callback (回掉，用于将变化后的数据传递给父组件)
 *
 * 插槽内容
 * 1. 表格顶部操作项
 * 2. 表格主体
 * 3. 默认分页
 */
export default {
    name: 'TableWrap',
    props: {
        total: {
            type: Number,
            default: 0
        }
    },

    watch: {
        total (v) {
            // console.log(v)
            this.currentTotal = v
        }
    },

    data () {
        return {
            currentPage: 1,
            pageSize: 20,
            currentTotal: this.total
        }
    },

    methods: {
        handleSizeChange (val) {
            this.pageSize = val
            this.currentPage = 1
            this.handleCallback()
        },

        handleCurrentChange (val) {
            this.currentPage = val
            this.handleCallback()
        },

        handleCallback () {
            const { currentPage, pageSize } = this
            this.$emit('callback', { pageSize, currentPage })
        }
    }
}
</script>

<style lang="scss" scoped>
// .cus_table_top_operate_part{
// float: right;
//     position: relative;
//     top: -63px;
// }
.el-table {
  overflow: auto !important;
}
.el-table__body-wrapper {
overflow: auto !important;
}
</style>

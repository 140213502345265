import Cookie from 'js-cookie'
import request, { httpManage } from '@/api/request'

/* 获取数据字典 */
export function reqDataDic (code) { return request({ url: `/dictionary/${code}/info` }) }

/* 通过令牌获取对应的应用集合 */
export function reqApplications () { return request({ url: `/token/${Cookie.get('token') ? Cookie.get('token') : sessionStorage.getItem('ivy-token')}/applications` }) }

/* 获取当前登录用户信息 */
export function reqCurrentUserInfo () { return request({ url: '/user/info' }) }

/* 查询租户树 */
export function reqTenantTree () { return request({ url: '/tenant/filter/tree' }) }

/* 查询租户下对应的部门树 */
export function reqTenantDeptTree (tenantId) { return request({ url: `/dept/tenant/tree?tenantId=${tenantId}` }) }

/* 查询租户下的部门对应的人员 跨租户 */
export function reqTenantDeptPeople (params) { return request({ url: '/user/page/dept/current/:deptId', params }) }

/* 查询租户 部门树 */
export function reqDepartmentTree () { return request({ url: '/dept/tenant/tree' }) }

/* 查询平台 部门树 */
export function reqAdminDepartmentTree () { return request({ url: '/dept/tree' }) }

/* 获取当前部门及子部门的人员 */
export function reqDepartmentPeopleAll (params) { return request({ url: '/user/page/dept/all/:id', params }) }

/* 获取当前部门的人员 */
export function reqDepartmentPeople (params) { return request({ url: '/tenants/user/page/dept/current/:id', params }) }

/* 获取当前部门的人员平台级 */
export function reqPingDepartmentPeople (params) { return request({ url: '/user/page/dept/current/:id', params }) }

/* 获取所有租户 */
export function reqTenantList (params) { return request({ url: '/tenant/page', params }) }

/* 文件上传 */
export function reqUploadFile (params) { return request({ url: 'storage/upload', method: 'post', params, commonPre: '/oss' }) }
export function reqUploadImg (params) { return '/oss/storage/upload' }

/* 部门查询 */
// export function reqDepartment () { return request({ url: '/dept/page' }) }

/**
 * @des 获取通用树
 */
/* 获取树型返回值 */
export function reqBaseTreeData (cate) { return httpManage({ url: `/basetree/getByCate/${cate}`, method: 'get' }) }

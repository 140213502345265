export const positiveInteger = (n) => {
    return /^[1-9][0-9]{0,10}$/.test(n)
}

/* 验证手机号 */
export const validateMobilePhone = (n) => {
    return /^1[0-9]{10}$/.test(n)
}

/* 验证邮箱 */
export const validateMail = (s) => {
    return /[a-zA-Z-_]+@[^.]+\.[^.]+/.test(s)
}

/* 匹配名称 以字母开头 包含字母数字 - _ @ & 字符 */
export const validateName = (s) => {
    return /^[a-zA-Z]([a-zA-Z0-9_&@-]){2,}$/.test(s)
}

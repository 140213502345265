import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import { setRouter, updateRouter } from '@/router/router'
import { reqDataDic, reqApplications, reqCurrentUserInfo, reqTenantList } from '@/api/common/common'
import education from './modules/education.js'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        education
    },
    state: {
        /* 当前登录用户信息 */
        user: {},
        /* 当前应用 */
        currentApp: {},
        currentAppCode: '',
        /* 应用以及包含的应用菜单 */
        applicationsData: [],
        /* 菜单栏的折叠与展开 */
        menuCollapse: false,
        /* 数据字典 */
        dataDictionary: {},

        /* 租户 部门树 */
        departmentTreeTenement: null,
        /* 平台 部门树 */
        departmentTreeAdmin: null,
        /* 所有租户列表 */
        tenantList: [],
        // 消息列表数据
        newsList: []
    },
    mutations: {
        /* 处理菜单的展开与隐藏 */
        TOGGLE_MENU: state => {
            state.menuCollapse = !state.menuCollapse
        },
        USER: (state, data) => {
            state.user = data
        },

        CURRENT_APP_SET: (state, data) => {
            state.currentApp = data
            state.currentAppCode = data.code
        },

        DATA_DICTIONARY: (state, data) => {
            state.dataDictionary[data.code] = data.data
        },

        APPLICATIONS_DATA: (state, payload) => {
            state.currentApp = payload.currentApp
            state.currentAppCode = payload.currentApp.code
            state.applicationsData = payload.data
        },

        DEPARTMENT_TREE: (state, data) => {
            if (data.type === 'admin') {
                state.departmentTreeAdmin = data.tree
            } else {
                state.departmentTreeTenement = data.tree
            }
        },

        TENANT_LIST: (state, data) => {
            state.tenantList = data
        },

        NEWS_LIST: (state, data) => {
            state.newsList = data
        },
    },
    actions: {
        actionToggleMenu ({ commit }) {
            commit('TOGGLE_MENU')
        },
        actionCurrentAppSet ({ commit, state }, id) {
            const currentApp = state.applicationsData.find(v => id === v.id)
            updateRouter(currentApp)
            commit('CURRENT_APP_SET', currentApp)
        },
        async acitonNewsList ({ commit }, data1) {
            commit('NEWS_LIST', data1)
        },
        async actionDataDic ({ commit, state }, code) {
            if (!state.dataDictionary[code]) {
                const { data: { dictionaryItem } } = await reqDataDic(code)
                commit('DATA_DICTIONARY', { code, data: dictionaryItem })
            }
        },

        async actionAppsData ({ commit }) {
            const { data } = await reqApplications()
            const { data: userInfo } = await reqCurrentUserInfo()
            data.sort((a, b) => (a.orderNum - b.orderNum))
            const appId = Cookies.get('currentAppId')
            let currentApp = data[0]
            if (appId) {
                currentApp = data.find(v => v.id === Number(appId))
                setRouter(currentApp)
            } else {
                Cookies.set('currentAppId', currentApp.id)
                setRouter(currentApp)
            }
            commit('APPLICATIONS_DATA', { data, currentApp })
            commit('USER', userInfo)
        },

        async actionDepTree ({ commit }) {
            const { data } = await reqApplications()
            data.sort((a, b) => (a.id - b.id))
            commit('DEPARTMENT_TREE', data)
        },

        async actionGetTenantList ({ commit }) {
            let { data } = await reqTenantList({ size: 1000 })
            data = data.records ? data.records : []
            commit('TENANT_LIST', data)
        },
    }
})

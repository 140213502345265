import request from '@/api/request'

/* 登录 */
export function login (data) {
    return request({
        url: '/auth/oauth/token',
        method: 'post',
        params: {
            grant_type: 'password',
            randomStr: Math.random().toString(36).substr(2),
            scope: 'server'
        },
        data,
        commonPre: '',
        excludeCode: true
    })
}
/* 外部访问登录 */
export function reqLogin (params) {
    return request({ url: '/etm-platform/login', method: 'get', commonPre: '', params, excludeCode: true })
}

/* 退出登录 */
export function reqLoginOut () {
    return request({ url: '/auth/token/logout', method: 'delete', commonPre: '' })
}

/* 获取登录用户信息 */
export function reqLoginUser () {
    return request({ url: '/user/info', method: 'get' })
}

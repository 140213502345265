<template>
    <div class="topStatus">
        <el-row :gutter="20">
            <el-col :span="4" v-for="item in devStatusBlock" :key="item.id" style="width:20%">
                <div class="box-card">
                    <div class="header">
                        <span>{{item.name}}</span>
                        <span>{{item.num}}</span>
                    </div>
                    <div class="main">
                        <el-progress type="circle" :stroke-width="strokeWidth" :width="width" :percentage="Number(((item.num / item.total) * 100).toFixed(0))"></el-progress>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { reqEqStatus } from '@/api/globalKanban/globalKanban.js'
export default {
    name: 'devStatusBlock', // 设备状态卡片
    data () {
        return {
            width: 90,
            strokeWidth: 8,
            devStatusBlock: []
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        /** 获取数据 */
        getData () {
            reqEqStatus().then(res => {
                this.devStatusBlock = [
                    {
                        name: '正常设备',
                        num: res.data.deviceNormal,
                        type: 'normal',
                        id: Math.random(),
                        color: '#4D6FF6',
                        total: res.data.deviceTotal
                    }, {
                        name: '异常设备',
                        num: res.data.deviceAbnormal,
                        type: 'abnormal',
                        id: Math.random(),
                        color: '#EA3434',
                        total: res.data.deviceTotal
                    }, {
                        name: '停机设备',
                        num: res.data.deviceShutdown,
                        type: 'downtime',
                        id: Math.random(),
                        color: '#F7A35C',
                        total: res.data.deviceTotal
                    }, {
                        name: '正常元数据',
                        num: res.data.dataNormal,
                        type: 'metadata',
                        id: Math.random(),
                        color: '#46EED1',
                        // total: res.data.dataTotal
                        total: res.data.deviceTotal

                    }, {
                        name: '正常视频源',
                        num: 80,
                        type: 'videoSource',
                        id: Math.random(),
                        total: 100
                    }
                ]
            }).catch()
            // this.devStatusBlock = [
            //     {
            //         name: '正常设备',
            //         num: 80,
            //         type: 'normal',
            //         id: Math.random(),
            //         total: 100
            //     }, {
            //         name: '异常设备',
            //         num: 10,
            //         type: 'abnormal',
            //         id: Math.random(),
            //         total: 100
            //     }, {
            //         name: '停机设备',
            //         num: 10,
            //         type: 'downtime',
            //         id: Math.random(),
            //         total: 100
            //     }, {
            //         name: '正常元数据',
            //         num: 806,
            //         type: 'metadata',
            //         id: Math.random(),
            //         total: 1000
            //     }, {
            //         name: '正常视频源',
            //         num: 80,
            //         type: 'videoSource',
            //         id: Math.random(),
            //         total: 100
            //     }
            // ]
        },

    }
}
</script>

<style lang="scss" scoped>
 .box-card{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background-color: #fff;
    box-shadow:0 2px 12px 0 #0000001a;
    .header{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        font-weight: bold;
        padding: 10px;
        span:first-child{
            flex: 2;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
        }
        span:last-child{
            color: #fff;
            background: #4d6ff6;
            padding: 0 5px;
        }
    }
    .main{
        margin: 5px auto;
    }
  }
  .el-row{
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 15px 5px 0;
      .el-col {
        margin-bottom: 20px;
        &:last-child {
        margin-bottom: 0;
        }
    }
  }
</style>

<template>
  <div>
    <div class="checkedInfo">
      <i class="el-icon-info"></i>
      <p>已选择<span>{{checkedNum}}</span>项</p>
      <el-button type="text" @click="toggleSelection()">清空</el-button>
    </div>
  </div>
</template>

<script>
export default {
    props: {
    /** 选中数 */
        checkedNum: {
            type: [Number, String],
            default: () => 0,
        },
    },

    data () {
        return {}
    },
    mounted () {},
    methods: {
        toggleSelection () {
            this.$parent.$parent.$refs.multipleTable.clearSelection()
            this.$parent.$parent.show = false
        },
    },
}
</script>

<style lang="scss" scoped>
</style>

export default {
    /* 获取url中的name, 这是针对特定的业务场景使用的 */
    getUrlName: (url) => {
        if (url) {
            const arr = url.split('/')
            return arr[arr.length - 1]
        }
        return ''
    },

    /* 根据树节点id 得到他的名称 */
    getTreeName: (treeData, id, key = 'id', children = 'children') => {
        // console.log(treeData, id)
        let re = ''

        const fn = (tree) => {
            for (let i = 0; i < tree.length; i++) {
                const val = tree[i]
                if (val[key] === id) {
                    re = val
                    return
                }
                if (val[children].length) fn(val[children])
            }
        }

        fn(treeData)
        return re || { }
    },

    /* 表格合并, 第一个参数  数据数组  第二个参数 子一级要合并的属性名或要采用的子一级的字段 第三个参数 要采用的子一级的字段 */
    tableMerge: function () {
        const params = arguments
        const length = params.length
        const _d = params[0]
        let tier = 'children'
        let useChildFields = []
        if (length === 2) {
            if (typeof params[1] === 'string') {
                tier = params[1]
            } else {
                useChildFields = params[1]
            }
        } else if (length === 3) {
            tier = params[1]
            useChildFields = params[2]
        }

        if (_d instanceof Array) {
            tier = tier || 'children'
            const list = []
            _d.forEach(parent => {
                const val = parent[tier]; const len = val.length
                val.forEach((child, index) => {
                    Object.keys(parent).forEach(key => {
                        if (key !== tier && !useChildFields.includes(key)) child[key] = parent[key]
                    })
                    if (len > 1) {
                        if (index === 0) {
                            child.mergeStart = len
                        } else {
                            child.beMerged = true
                        }
                    }
                    list.push(child)
                })
            })
            return list
        } else {
            return []
        }
    }
}

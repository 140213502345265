
<template>
  <div class="UploadPhoto">
    <el-upload
      multiple
      class="upload-demo"
      :action="imgData.url"
      :headers="imgData.headers"
      :on-preview="handlePictureCardPreview"
      :before-remove="beforeRemove"
      :on-remove="handleRemove"
      :on-change="handleChange"
      :file-list="fileList"
      :on-exceed="exceed"
      :limit="imgData.limit"
      :accept="imgData.accept"
      :data="{bucket :imgData.bucket,directory:imgData.directory }"
      list-type="picture-card"
      :disabled="dis">
     <i class="el-icon-plus"></i>
     <div v-show="!dis" slot="tip" class="el-upload__tip">{{imgData.propose}}</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { reqUploadImg } from '@/api/common/common'
import { getDomain } from '@/utils/common'
export default {
    props: {
        imgUrls: { type: Array, default: () => [] },
        who: {
            type: String,
            default: ''
        },
        dis: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            fileList: [],
            imageUrl: [],
            imgData: {
                // 是否禁用上传
                isUploading: false,
                // 设置上传的请求头部
                headers: { Authorization: Cookies.get('Authorization') },
                // 上传的地址
                url: `${getDomain({ pre: '' })}${reqUploadImg()}`,
                limit: 10,
                bucket: 'ivy-security-bucket',
                directory: 'HIDDEN_DANGER_TROUBLESHOOTING',
                accept: '.jpg,.png',
                propose: '只能上传jpg/png文件，大小不超过5M',
            }
        }
    },
    watch: {
        imgUrls: {
            handler: function (value) {
                this.fileList = value.map((item) => {
                    return {
                        url: item.fileUrl,
                        name: item.fileName,
                        id: item.id
                    }
                })
            }
        },
    },
    created () {
        // this.fileList = this.imgUrls
    },
    methods: {
        beforeRemove (file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`)
        },
        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        // 超出设定最大值
        exceed () {
            this.$message.warning(`最多只能上传${this.imgData.limit}个文件`)
        },
        handleChange (file, fileList) {
            // this.fileList = fileList
            this.$emit('toParent', fileList, this.who)
        },
        handleRemove (file, fileList) {
            this.$emit('toParent', fileList, this.who)
        }
    },
}

</script>

<style scoped>

</style>

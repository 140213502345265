/* eslint-disable no-undef */
/* eslint-disable no-undef */

<template>
  <div id="app">
     <router-view />
  </div>
</template>

<script>

export default {
    mounted () {
        // NProgress.done()
    }
}
</script>

<style lang="scss">
@import "./styles/index.scss";
// .v-modal {
//   z-index: 0 !important;
// }
</style>

<template>
    <div id="content">
        <div v-if="list1" id="question">
         <SearchWrap :searchOptions="searchOptions" :inline="inline" @callback="handleSearchCallback"></SearchWrap>
         <TableWrap @callback="handlePagination" :total="total">
          <!-- <template slot="tablesearch">
            <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
          </template> -->
          <template slot="tableOperate">
              <el-button type="primary" size="small" @click="add">新增</el-button>
          </template>
          <el-table :data="tableData" :header-cell-style="{background:'#FAFAFA'}">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column label="需求描述" :show-overflow-tooltip="true" prop="equirementsDescribe"></el-table-column>
              <el-table-column label="编号" prop="code"></el-table-column>
              <el-table-column label="状态" prop="status">
                 <template slot-scope="scope">
                    {{
                          scope.row.status == 0
                          ? "待响应"
                          : scope.row.status == 1
                          ? "待处理"
                          : scope.row.status == 2
                          ? '待验收'
                          : scope.row.status == 3
                          ? '待优化'
                          : scope.row.status == 4
                           ? '已归档'
                          : scope.row.status == 5
                           ? '未完成'
                          : scope.row.status == 6
                    }}
                  </template>
              </el-table-column>
              <el-table-column label="填写日期" prop="fillingTime" width="100">
              </el-table-column>
              <el-table-column label="发起人" prop="fillingUser"></el-table-column>
              <el-table-column label="相关产品" prop="productCategory"></el-table-column>
              <el-table-column label="业务功能" prop="businessFunctions"></el-table-column>
              <el-table-column label="响应人" prop="responseUser"></el-table-column>
              <el-table-column label="指定处理人" prop="designatedHandler"></el-table-column>
              <el-table-column label="完成人" prop="involvedUser"></el-table-column>
              <el-table-column label="验收人" prop="acceptanceUser"></el-table-column>
              <el-table-column label="操作">
                  <template slot-scope="scope">
                        <el-dropdown trigger="hover" @command="handleCommand">
                            <el-button type="primary" size="mini" plain>
                                编辑<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="`apply&${scope.row.id}&${scope.row.fillingUser}`" v-show="(scope.row.status===0 || scope.row.status===5) && !isTenant ">响应</el-dropdown-item>
                                <el-dropdown-item :command="`del&${scope.row.id}`">删除</el-dropdown-item>
                                <el-dropdown-item :command="`accept&${scope.row.id}&${scope.row.fillingUser}`" v-show="(scope.row.status===2) && !isTenant">验收</el-dropdown-item>
                                <el-dropdown-item :command="`complete&${scope.row.id}&${scope.row.fillingUser}`" v-show="(scope.row.status===1) && !isTenant">完成</el-dropdown-item>
                                <el-dropdown-item :command="`detail&${scope.row.id}`">详情</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                  </template>
              </el-table-column>
          </el-table>
      </TableWrap>
        <!-- 人员多选 -->
            <el-dialog :title="dialogTitle" :visible.sync='dialogVisible' @close='cancelDialog'>
                <DepartmentTree :type="admin" v-model="department" ref="departmentTree"/>
                <div class="cus_dialog_footer">
                    <el-button type="primary" size="mini" @click="change" >确认</el-button>
                    <el-button type="primary" size="mini" plain @click="cancelDialog">取消</el-button>
                </div>
            </el-dialog>
               <el-dialog :title="dialogTitle" :visible.sync='dialogFormVisibleadd' @close='cancel'>
                  <el-form :model="addform" ref="formRefadd" :rules="addrules">
                      <el-row :gutter="20">
               <el-col :span="12">
                    <el-form-item prop="fillingTime" label="填写日期" >
                     <el-date-picker type="date" placeholder="请选择日期" disabled v-model="fillingTime" style="width: 100%;"></el-date-picker>
                 </el-form-item>
               </el-col>
               <el-col :span="12">
                  <el-form-item prop="fillingUser" label="发起人">
                  <el-input v-model="fillingUser"  disabled></el-input>
              </el-form-item>
               </el-col>
             </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="相关产品" prop="productCategory">
               <el-select @change="handleChange" :disabled="isDisabled" placeholder="请选择" v-model="addform.productCategory" style="width:100%">
                  <el-option v-for="item in listOption1" :value="{value:item.id,label:item.name}" :key='item.id' :label="item.name"></el-option>
                </el-select>
            </el-form-item>
              </el-col>
               <el-col :span="12">
                   <el-form-item label="业务功能">
                  <el-cascader
                  v-model="val"
                  :options="options"
                   ref="myCascader"
                   style="width: 100%;"
                  :props="{
                      checkStrictly:true,
                      children: 'children',
                      value: 'id',
                      label: 'name',
                      emitPath: false
                  }"
                  @change="handleChange1"
                  :show-all-levels="false"
                  clearable
                  >
                </el-cascader>
            </el-form-item>
               </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                  <el-form-item label="期望完成时间">
               <el-date-picker value-format="yyyy-MM-dd" :picker-options="pickerOptions" type="date" placeholder="请选择日期" v-model="addform.completionDate" style="width: 100%;"></el-date-picker>
            </el-form-item>
              </el-col>
               <el-col :span="12">
                     <el-form-item prop="emergency" label="紧急程度">
               <el-select placeholder="请选择" v-model="addform.emergency" style="width:100%">
                  <el-option v-for="item in listOption2" :value="item.id" :key='item.id' :label="item.name"></el-option>
                </el-select>
            </el-form-item>
               </el-col>
            </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
                  <el-form-item prop="equirementsDescribe" label="问题及需求">
              <el-input type="textarea" v-model="addform.equirementsDescribe" maxlength="255"></el-input>
            </el-form-item>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="24">
                 <el-form-item label="附图" >
               <el-input v-model="addform.problemImages" v-show="false"></el-input>
               <uploadFileWrap :imgUrls="imgUrls"  @toParent="uploadImg"/>
            </el-form-item>
            </el-col>
          </el-row>
                  </el-form>
                <div class="cus_dialog_footer" slot="footer" style="text-align:right">
                    <el-button type="primary" size="mini" @click="submit" >确认</el-button>
                    <el-button type="primary" size="mini" plain @click="cancel">取消</el-button>
                </div>
            </el-dialog>
            <!-- 响应 -->
             <el-dialog :title="dialogTitle" :visible.sync='dialogFormVisiblexiang' @close='cancel1'>
                  <el-form :model="xiangform" ref="formRefxiang" :rules="xiangrules">
                  <el-row :gutter="20">
                 <el-col :span="24">
                          <el-form-item label="响应人"  style="width: 100%">
              <el-input v-model="responseUser" style="width: 100%" disabled autocomplete="off"></el-input>
            </el-form-item>
                 </el-col>
               </el-row>
               <el-row :gutter="20">
                 <el-col :span="24">
                     <el-form-item label="响应时间"  style="width: 100%">
             <el-date-picker type="date" placeholder="请选择日期" disabled v-model="responseTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
                 </el-col>
               </el-row>
               <el-row :gutter="20">
                 <el-col :span="24">
                     <el-form-item prop="designatedHandler"  style="width: 100%" label="指定处理人">
                      <el-input v-model="xiangform.designatedHandlername" @focus="changeZhiPerson" style="width: 100%" autocomplete="off"></el-input>
                    </el-form-item>
                 </el-col>
               </el-row>
               <el-row :gutter="20">
                 <el-col :span="24">
                        <el-form-item prop="treatmentDescribe"  style="width: 100%" label="处理建议">
                          <el-input type="textarea" maxlength="255" v-model="xiangform.treatmentDescribe" style="width: 100%" autocomplete="off"></el-input>
                        </el-form-item>
                 </el-col>
               </el-row>
                  </el-form>
                <div class="cus_dialog_footer" style="text-align:right">
                    <el-button type="primary" size="mini" @click="submit" >确认</el-button>
                    <el-button type="primary" size="mini" plain @click="cancel1">取消</el-button>
                </div>
            </el-dialog>
            <!-- 完成 -->
             <el-dialog :title="dialogTitle" :visible.sync='dialogFormVisiblewan' @close='cancel2'>
                  <el-form :model="wanform" ref="formRefwan" :rules="wanrules">
                  <el-row :gutter="20">
                <el-col :span="12">
                     <el-form-item label="完成人">
              <el-input v-model="involvedUser" disabled autocomplete="off"></el-input>
            </el-form-item>
                </el-col>
                <el-col :span="12">
                      <el-form-item label="完成时间">
                  <el-date-picker type="date" disabled placeholder="请选择日期" v-model="completionTime" style="width: 100%;"></el-date-picker>
            </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item prop="problem" label="是否遗留问题">
                  <el-select @change="handleChangeTrue" :disabled="isDisabled" placeholder="请选择" v-model="wanform.problem" style="width:100%">
                      <el-option v-for="item in listOption4" :value="item.id" :key='item.id' :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                </el-col>
              </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                   <el-form-item prop="problemsDescribe" v-if="show"  label="遗留问题描述意见">
              <el-input type="textarea" maxlength="255" v-model="wanform.problemsDescribe" autocomplete="off"></el-input>
            </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                   <el-form-item prop="resultDescribe" label="处理结果">
                    <el-input type="textarea" maxlength="255" v-model="wanform.resultDescribe" autocomplete="off"></el-input>
                  </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                    <el-form-item label="附图">
                      <el-input v-model="wanform.resultImages" v-show="false"></el-input>
                      <uploadFileWrap :imgUrls="imgUrlsTwo"  @toParent="uploadImgTwo"/>
                    </el-form-item>
              </el-col>
            </el-row>
                  </el-form>
                <div class="cus_dialog_footer"  style="text-align:right">
                    <el-button type="primary" size="mini" @click="submit" >确认</el-button>
                    <el-button type="primary" size="mini" plain @click="cancel2">取消</el-button>
                </div>
            </el-dialog>
            <!-- 验收 -->
             <el-dialog :title="dialogTitle" :visible.sync='dialogFormVisibleyan' @close='cancel3'>
                  <el-form :model="yanform" ref="formRefyan" :rules="yanrules">
                  <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item style="width: 100%;" prop="responseUser" label="验收人">
                  <el-input disabled v-model="acceptanceUser" style="width: 100%;" autocomplete="off"></el-input>
                </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="acceptanceTime" style="width: 100%;" label="验收日期">
                  <el-date-picker type="date" value-format="yyyy-MM-dd" :picker-options="pickerOptions" placeholder="请选择日期" v-model="yanform.acceptanceTime" style="width: 100%;"></el-date-picker>
                </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item prop="status" id="sep" label="验收结论">
               <el-select @change="handleChangeJie" :disabled="isDisabled" placeholder="请选择" v-model="status"  style="width:100%">
                  <el-option v-for="item in listOption3" :value="{value:item.id,label:item.name}" :key='item.id' :label="item.name"></el-option>
                </el-select>
            </el-form-item>
                </el-col>
              </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                   <el-form-item label="验收意见">
              <el-input type="textarea" style="width: 100%;" maxlength="255" v-model="yanform.acceptanceDescribe"></el-input>
            </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                  <el-form-item id="sep" label="验收评价">
                  <el-rate @change="ping" style="width: 100%;" allow-half v-model="value1"></el-rate>
                </el-form-item>
              </el-col>
            </el-row>
                  </el-form>
                <div class="cus_dialog_footer"  style="text-align:right">
                    <el-button type="primary" size="mini" @click="submit" >确认</el-button>
                    <el-button type="primary" size="mini" plain @click="cancel3">取消</el-button>
                </div>
            </el-dialog>
        </div>
        <ced-question v-else @back="back" :id="id1"></ced-question>
    </div>
</template>

<script>
import { reqQuestionList, reqQuestionListAdd, reqQuestionDel, reqQuestionListOption } from '@/api/question/question.js'
import { reqStytemList, reqMoKuai } from '@/api/question/safty.js'
import tableMixin from '@/mixIn/tableMixIn'
import DepartmentTree from '@/components/departmentTree'
import { mapState } from 'vuex'
import dayjs from 'dayjs'
import uploadFileWrap from '@/components/uploadFileWrap'
import CedQuestion from '@/views/question/cedQuestion.vue'
// import Cookies from 'js-cookie'
export default {
    name: 'question',
    mixins: [tableMixin],
    components: { DepartmentTree, uploadFileWrap, CedQuestion },
    props: {
        list: {
            type: Boolean
        }
    },
    data () {
        return {
            list1: this.list,
            id1: '',
            searchOptions: [
                { type: 'select', label: '状态', options: [], key: 'status', clearable: true },
                { type: 'input', key: 'designatedHandler', label: '指定处理人' },
                { type: 'date', label: '填写日期', key: 'starTime' },
                { type: 'date', label: '至', key: 'endTime' },
                { type: 'input', key: 'fillingUser', label: '发起人' },
                { type: 'select', key: 'productCategory', options: [], label: '相关产品', clearable: true },
            ],
            // reqTableData: reqQuestionList,
            tableData: [],
            form: {},
            optionType: '',
            dialogFormVisible: false,
            dialogVisible: false,
            radio: false,
            department: {},
            title: '',
            inline: true,
            responseUser: '', // 响应人
            total: this.total,
            loading: '',
            listOption1: [], // 相关产品
            listOption2: [
                {
                    id: 0, name: '低'
                },
                {
                    id: 1, name: '一般'
                },
                {
                    id: 2, name: '中'
                },
                {
                    id: 3, name: '高'
                }
            ], // 紧急程度
            listOption3: [
                {
                    id: 4, label: '归档', name: '归档'
                },
                {
                    id: 3, label: '待优化', name: '待优化'
                },
                {
                    id: 5, label: '未完成', name: '未完成'
                },
            ], // 验收结论
            listOption4: [
                {
                    id: 0, label: '否', name: '否'
                },
                {
                    id: 1, label: '是', name: '是'
                },
            ], // 是否遗留问题
            options: [], // 业务功能
            pickerOptions: {
                disabledDate (time) {
                    // return time.getTime() > Date.now() - 8.64e6
                    return time.getTime() < Date.now() - 8.64e7
                },
            },
            show: false, // 是否显示意见
            imgUrls: [], // 图片地址
            scenePhotos: [], // 图片
            isDisabled: false,
            admin: 'admin',
            val: [],
            dialogTitle: '',
            fillingUser: '', // 发起人
            involvedUser: '', // 完成人
            acceptanceUser: '', // 验收人
            tenantCode: '', // 当前用户名字
            id: '', // 当前操作数据的id
            problem: '', // 是否遗留问题
            status: '', // 当前状态
            acceptanceEvaluation: '', // 验收评价
            addrules: {
                productCategory: [
                    { required: true, message: '请选择相关产品', trigger: 'change' }
                ],
                emergency: [
                    { required: true, message: '请选择紧急程度', trigger: 'change' }
                ],
                equirementsDescribe: [
                    { required: true, message: '请输入问题及需求', trigger: 'blur' }
                ],
            },
            xiangrules: {
                designatedHandler: [
                    { required: true, message: '请选择指定处理人', trigger: 'change' }
                ],
                designatedHandlername: [
                    { required: true, message: '请选择指定处理人', trigger: 'change' }
                ],
                treatmentDescribe: [
                    { required: true, message: '请输入处理建议', trigger: 'blur' }
                ],
            },
            wanrules: {
                problem: [
                    { required: true, message: '请选择是否遗留问题', trigger: 'change' }
                ],
                resultDescribe: [
                    { required: true, message: '请输入处理结果', trigger: 'blur' }
                ],
                problemsDescribe: [
                    { required: true, message: '请输入问题描述意见', trigger: 'blur' }
                ],
            },
            yanrules: {
                acceptanceTime: [
                    { required: true, message: '请选择验收日期', trigger: 'change' }
                ],
                status: [
                    { required: true, message: '请选择验收结论', trigger: 'change' }
                ]
            },
            imgUrlsTwo: [],
            scenePhotosTwo: [],
            value1: 0, // 验收评价绑定值
            emergency: '',
            isTenant: false,
            fillingTime: '', // 填写日期
            responseTime: '', // 响应日期
            completionTime: '', // 完成日期
            xiangguanList: [], // 搜索栏上的相关产品
            xiangguanList1: [],
            imgUrlList: [],
            imgUrlList1: [],
            addform: {},
            dialogFormVisibleadd: false,
            xiangform: {
                designatedHandler: '',
                treatmentDescribe: '',
                designatedHandlername: ''
            },
            dialogFormVisiblexiang: false,
            wanform: {
                problemsDescribe: '',
                problem: '',
                resultDescribe: ''
            },
            dialogFormVisiblewan: false,
            yanform: {},
            dialogFormVisibleyan: false
        }
    },
    computed: {
        ...mapState(['user'])
    },
    created () {
        const date = new Date()
        this.fillingTime = this.dateFormat(date, 'yyyy-MM-dd hh:mm:ss')
        this.responseTime = this.dateFormat(date, 'yyyy-MM-dd hh:mm:ss')
        this.completionTime = this.dateFormat(date, 'yyyy-MM-dd hh:mm:ss')
        const list = [
            {
                value: 0, label: '待响应'
            },
            {
                value: 1, label: '待处理'
            },
            {
                value: 2, label: '待验收'
            },
            {
                value: 3, label: '待优化'
            },
            {
                value: 4, label: '已归档'
            },
            {
                value: 5, label: '未完成'
            },
        ]
        this.searchOptions[0].options = list
        // 应用列表-----相关产品
        reqStytemList().then((res) => {
            // console.log(res.data.records)
            this.listOption1 = res.data.records
            this.xiangguanList = res.data.records
            // console.log(this.listOption1)
            const obj = {
                id: 1000,
                name: '其他'
            }
            this.listOption1.push(obj)
            this.xiangguanList.forEach((item) => {
                const obj = {}
                obj.value = item.name
                obj.label = item.name
                this.xiangguanList1.push(obj)
            })
            this.searchOptions[5].options = this.xiangguanList1
        })
        this.getTableData()
        this.responseUser = this.user.sysUser.name
        this.fillingUser = this.user.sysUser.name
        this.involvedUser = this.user.sysUser.name
        // this.tenantCode = this.user.sysUser.name
        this.acceptanceUser = this.user.sysUser.name
        if (this.user.source === 'tenant') {
            this.isTenant = true
        } else {
            this.isTenant = false
        }
        this.getTableData()
    },
    methods: {
        getTableData () {
            const { query, current, size } = this
            reqQuestionList({ ...query, current, size }).then((res) => {
                // console.log(res)
                res.data.records.forEach((item) => {
                    item.fillingTime = dayjs(item.fillingTime).format('YYYY-MM-DD')
                })
                // res.data.records = dayjs(res.data.records).format('YYYY-MM-DD')
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
        dateFormat (thisDate, fmt) {
            var o = {
                'M+': thisDate.getMonth() + 1,
                'd+': thisDate.getDate(),
                'h+': thisDate.getHours(),
                'm+': thisDate.getMinutes(),
                's+': thisDate.getSeconds(),
                'q+': Math.floor((thisDate.getMonth() + 3) / 3),
                S: thisDate.getMilliseconds()
            }
            if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (thisDate.getFullYear() + '').substr(4 - RegExp.$1.length)) }
            for (var k in o) {
                if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
            }
            return fmt
        },
        handleCommand (command) {
            command = command.split('&')
            const id = command[1]
            // console.log(command)
            this.currentId = id
            // console.log(id)
            if (command[0] === 'apply') {
                this.apply(command[1], command[2])
            } else if (command[0] === 'del') {
                this.del(command[1])
            } else if (command[0] === 'accept') {
                this.accept(command[1], command[2])
            } else if (command[0] === 'complete') {
                this.complete(command[1], command[2])
            } else {
                this.detail(command[1])
            }
        },
        submit () {
            if (this.optionType === 'add') {
                this.addform.fillingTime = dayjs(this.fillingTime).format('YYYY-MM-DD')
                this.addform.problemImages = this.imgUrlList.map(item => {
                    return item.fileUrl
                    // return item.fileName
                }).toString()
                if (!this.addform.completionDate) {
                    this.$delete(this.addform, 'completionDate')
                }
                // console.log(this.addform)
                this.$refs.formRefadd.validate((valid) => {
                    if (valid) {
                        // console.log(this.addform)
                        reqQuestionListAdd(this.addform).then((res) => {
                            // console.log(res)
                            if (res.code === 0) {
                                this.$message('新增成功')
                                this.getTableData()
                                this.dialogFormVisibleadd = false
                                this.imgUrls = []
                                this.scenePhotos = []
                                this.imgUrlList = []
                                this.addform.equirementsDescribe = ''
                                this.addform.emergency = ''
                                this.emergency = ''
                                this.val = []
                                this.addform.productCategory = ''
                                this.addform.completionDate = ''
                            }
                        })
                    }
                })
            } else if (this.optionType === 'apply') {
                // 响应
                this.$refs.formRefxiang.validate((valid) => {
                    // debugger
                    if (valid) {
                        this.xiangform.responseTime = dayjs(this.responseTime).format('YYYY-MM-DD')
                        this.xiangform.id = this.id
                        this.xiangform.responseUser = this.responseUser
                        this.xiangform.status = 1
                        reqQuestionListOption(this.xiangform).then((res) => {
                            if (res.code === 0) {
                                this.getTableData()
                                this.dialogFormVisiblexiang = false
                                this.xiangform.treatmentDescribe = ''
                                // this.xiangform = {}
                                this.xiangform.designatedHandler = ''
                                this.xiangform.designatedHandlername = ''
                                // this.form = {}
                            }
                        })
                    }
                })
            } else if (this.optionType === 'complete') {
                // 完成
                this.$refs.formRefwan.validate((valid) => {
                    if (valid) {
                        this.wanform.completionTime = dayjs(this.completionTime).format('YYYY-MM-DD')
                        this.wanform.status = 2
                        this.wanform.involvedUser = this.involvedUser
                        this.wanform.id = this.id
                        this.wanform.resultImages = this.imgUrlList1.map(item => {
                            return item.fileUrl
                        }).toString()
                        reqQuestionListOption(this.wanform).then((res) => {
                            if (res.code === 0) {
                                this.getTableData()
                                this.dialogFormVisiblewan = false
                                this.problem = ''
                                this.wanform.problemsDescribe = ''
                                this.wanform.resultDescribe = ''
                                this.wanform.problem = ''
                                this.show = false
                                this.imgUrlsTwo = []
                                this.scenePhotosTwo = []
                                this.imgUrlList1 = []
                            }
                        })
                    }
                })
            } else {
                // 验收请求
                // this.form.acceptanceTime = dayjs(this.form.acceptanceTime).format('YYYY-MM-DD')
                this.$refs.formRefyan.validate((valid) => {
                    this.yanform.id = this.id
                    this.yanform.acceptanceUser = this.acceptanceUser
                    if (valid) {
                        reqQuestionListOption(this.yanform).then((res) => {
                            if (res.code === 0) {
                                this.getTableData()
                                this.dialogFormVisibleyan = false
                                this.status = ''
                                this.yanform.acceptanceTime = ''
                                this.yanform.acceptanceDescribe = ''
                                this.value1 = 0
                            }
                        })
                    }
                })
            }
        },
        cancel () {
            this.dialogFormVisible = false
            this.dialogFormVisibleadd = false
            this.$refs.formRefadd.resetFields()
            this.val = []
            this.imgUrls = []
            this.scenePhotos = []
            // this.addform.productCategory = ''
            this.addform.completionDate = ''
            // this.addform.equirementsDescribe = ''
        },
        cancel1 () {
            this.xiangform.designatedHandler = ''
            this.xiangform.treatmentDescribe = ''
            this.xiangform.designatedHandlername = ''
            this.$refs.formRefxiang.resetFields()
            this.dialogFormVisiblexiang = false
        },
        cancel2 () {
            this.dialogFormVisiblewan = false
            this.wanform.problemsDescribe = ''
            this.wanform.resultDescribe = ''
            this.$refs.formRefwan.resetFields()
            this.show = false
            this.imgUrlsTwo = []
            this.scenePhotosTwo = []
        },
        cancel3 () {
            this.dialogFormVisibleyan = false
            this.status = ''
            this.yanform.acceptanceTime = ''
            this.yanform.acceptanceDescribe = ''
            this.value1 = 0
            this.status = ''
        },
        add () {
            this.optionType = 'add'
            this.title = '新增问题'
            this.dialogFormVisibleadd = true
        },
        // 响应
        apply (id, name) {
            // console.log(row)
            this.optionType = 'apply'
            this.title = '问题响应'
            this.dialogFormVisiblexiang = true
            // console.log(id)
            this.id = id
            // this.responseUser = name
            // this.form.responseUser = name
        },
        del (id) {
            this.$confirm('确认删除？', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                reqQuestionDel(id).then((res) => {
                    this.getTableData()
                    this.$message.success('删除成功')
                })
            }).catch(() => {
                this.$message.info('已取消删除！')
            })
        },
        detail (id, type) {
            // this.$router.push({ name: 'cedQuestion', params: { type }, query: { id } })
            this.$emit('question', id, false)
            this.list1 = false
            this.id1 = id
        },
        // 验收
        accept (id, name) {
            this.optionType = 'accept'
            this.title = '问题验收'
            this.dialogFormVisibleyan = true
            this.id = id
            // this.form.acceptanceUser = name
            // this.acceptanceUser = name
        },
        // 完成
        complete (id, name) {
            this.optionType = 'complete'
            this.title = '问题处理'
            this.dialogFormVisiblewan = true
            this.id = id
            // this.form.involvedUser = name
            // this.involvedUser = name
        },
        handlePagination (data) {
            // console.log(data)
            this.size = data.pageSize
            this.current = data.currentPage
            this.getTableData()
        },
        handleSearchCallback ({ data }) {
            this.query = data
            // console.log(data)
            this.current = 1
            this.getTableData()
            // reqQuestionListInfo({ ...this.query }).then((res) => {
            //     console.log(res)
            //     res.data.forEach((item) => {
            //         item.fillingTime = dayjs(item.fillingTime).format('YYYY-MM-DD')
            //     })
            //     this.tableData = res.data
            // })
            // this.getTableData()
        },
        // 相关产品选择事件
        handleChange (item) {
            // const { value, label } = item
            // this.form.systemId = String(item.value)
            // this.form.systemName = item.label
            const { value, label } = item
            // console.log(value, label)
            const appId = value
            this.form.productCategory = label
            this.addform.productCategory = label
            // this.productCategory = label
            if (appId === 1000) {
                this.options = []
            } else {
                // const appId = appId
                if (appId === 31) {
                // 模块列表
                    reqMoKuai(appId).then((res) => {
                    // console.log(res.data)
                        res.data.forEach(item => {
                            item.children.forEach(item1 => {
                                // 处理不为按钮的菜单
                                item1.children = item1.children.filter((item2) => {
                                    return item2.type === '0'
                                })
                                // 处理pageComponent为null的
                                // item1.children = item1.children.filter((item2) => {
                                //     return item2.pageComponent !== null
                                // })
                            })
                        })
                        res.data[0].name = res.data[0].name.replace(/\s/g, '')
                        res.data[1].name = res.data[1].name.replace(/\s/g, '')
                        res.data[2].name = res.data[2].name.replace(/\s/g, '')
                        res.data[4].name = res.data[4].name.replace(/\s/g, '')
                        // console.log(res.data)
                        this.options = res.data
                    })
                } else {
                // 模块列表
                    reqMoKuai(appId).then((res) => {
                        // console.log(res.data)
                        res.data.forEach(item => {
                            item.children.forEach(item1 => {
                                // 处理不为按钮的菜单
                                item1.children = item1.children.filter((item2) => {
                                    return item2.type === '0'
                                })
                                // 处理pageComponent为null的
                                // item1.children = item1.children.filter((item2) => {
                                //     return item2.pageComponent !== null
                                // })
                            })
                        })
                        // console.log(res.data)
                        this.options = res.data
                    })
                }
            }
        },
        // 业务功能选择事件
        handleChange1 (value) {
            // console.log(value, this.$refs.myCascader.getCheckedNodes()[0].pathLabels[this.$refs.myCascader.getCheckedNodes()[0].pathLabels.length - 1])
            // console.log(value, this.$refs.myCascader.getCheckedNodes()[0].pathLabels)
            this.form.businessFunctions = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[this.$refs.myCascader.getCheckedNodes()[0].pathLabels.length - 1]
            this.addform.businessFunctions = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[this.$refs.myCascader.getCheckedNodes()[0].pathLabels.length - 1]
        },
        // 是否遗留问题
        handleChangeTrue (item) {
            // console.log(item)
            if (item === 1) {
                this.show = true
                // this.problem = item.label
                // this.form.problem = item.value
                // this.wanproblem = item.label
                // this.wanform.problem = item.value
            } else {
                this.show = false
                // this.problem = item.label
                // this.form.problem = item.value
                // this.wanproblem = item.label
                // this.wanform.problem = item.value
            }
        },
        // 紧急程度选择事件
        handleChangeDeep (item) {
            // console.log(item)
            const { value, label } = item
            // this.addform.emergency = Number(value)
            this.emergency = label
            this.form.emergency = Number(value)
        },
        // 验收结论选择事件
        handleChangeJie (item) {
            // console.log(item)
            const { value } = item
            if (value === 5) {
                this.status = item.label
                this.form.status = 0
                this.yanform.status = 0
            }
            this.status = item.label
            this.form.status = value
            this.yanform.status = value
        },
        // 指定处理人选择事件
        changeZhiPerson () {
            this.dialogVisible = true
            this.radio = true
        },
        // 确定选人事件
        change () {
            // console.log(this.department)
            this.xiangform.designatedHandlername = this.department.people.map(v => v.label).join()
            console.log(this.form.designatedHandler)
            this.xiangform.designatedHandler = this.department.people.map(v => v.id).join()
            // console.log(this.form.designatedHandler)
            this.dialogVisible = false
            this.$refs.departmentTree.reset()
        },
        cancelDialog () {
            this.dialogVisible = false
        },
        uploadImg (res) {
            // console.log(res)
            // const urlArr = []
            this.imgUrlList = res.map(item => {
                if (item.response) {
                    return { fileUrl: item.response.data.objectUrl, fileName: item.name }
                    // return { fileUrl: item.response.data.objectUrl, fileName: item.objectKey }
                } else {
                    return { fileUrl: item.url, fileName: item.name }
                    // return { fileUrl: item.url, fileName: item.objectKey }
                }
            })
            // console.log(this.imgUrlList)
        },
        uploadImgTwo (res) {
            this.imgUrlList1 = res.map(item => {
                if (item.response) {
                    return { fileUrl: item.response.data.objectUrl, fileName: item.name }
                } else {
                    return { fileUrl: item.url, fileName: item.name }
                }
            })
        },
        // 验收评价改变事件
        ping () {
            // console.log(this.value1)
            this.form.acceptanceEvaluation = Number(this.value1)
            this.yanform.acceptanceEvaluation = Number(this.value1)
        },
        back () {
            this.$nextTick(() => {
                this.list1 = true
                this.current = 1
                this.getTableData()
            })
            this.$forceUpdate()
            // console.log(this)
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/variable.scss";
#content{
  width: 100%;
  height: 100%;
  // #question {
  .cus_search_wrap {
    padding: 15px ;
    border: 1px solid $c3;
    background-color: $c1;
    width: 850px !important;
    float: left;
    margin: 0 20px 2px 0;
    box-shadow: 0px 2px 4px rgba(210, 210, 210, 0.5);
    opacity: 1;
    box-sizing: border-box;
}
.more_btn{
    font-size: 14px;
    color: #6887FF;
    margin: 0 6px;
    cursor: pointer;
}
.m-up, .m-down{
    display: inline-block;
    height: 22px;
    width: 22px;
    background: no-repeat;
    background-position: center bottom;
    position: relative;
    left: -4px
}
.m-down{
    background-image: url(~@/assets/image/m-up.png);
    background-size: 20px auto;
    top: 5px;
}
.m-up{
    background-size: 11px auto;
    top: 1px;
    background-image: url(~@/assets/image/m-down.png);

}
.cus_table_top_operate_part{
float: right;
    position: relative;
    // top: -63px;
}
.el-form-item__label {
  width: 82px !important;
}
  .el-dialog .el-form-item {
    display: flex;
    align-items: center;
}
.el-dialog .el-form-item .el-form-item__label {
     width: 130px !important;
}
.el-dialog .el-form-item .el-form-item__content {
  width: 214px !important;
}
.el-textarea__inner {
  height: 160px !important;
}
// .el-col-12 .el-form-item .el-form-item__label {
//     width: 103px !important;
//   }
  .el-col-12 .el-form-item .el-form-item__content {
   width: 100% !important;
  }
  // .el-dialog .el-col-6 .el-form-item  .el-form-item__content{
  //   width: 214px !important;
  // }
.el-col-24 .el-form-item .el-form-item__content{
    width: 100% !important;
  }
  .el-col-24 .el-form-item .el-form-item__label{
    width: 118px !important;
  }

// .el-form--inline .el-form-item {
//   margin-right: 27px !important;
// }
#questionDi {
  z-index: 99999 !important;
}

// }
}

</style>

<template>
    <div :class="['main-layout', menuCollapse ? 'menu_fold': 'menu_unfold']">
        <el-container style="height: 100%;">
            <el-header><Header @newsDetail="newsDetail" @news="news" @appChange="appChange" @change="change" @save="save"></Header></el-header>
            <el-container v-show="isPlatform">
                   <el-aside v-show="show===0" class="nav-el-aside"><Navbar></Navbar></el-aside>
                    <el-container v-show="show===0">
                        <el-header class="tags"><TagsNav ref="tagNav"></TagsNav></el-header>
                        <el-main>
                            <router-view></router-view>
                        </el-main>
                    </el-container>
                  <Question :list="list" @question="question" v-show="show===1"/>
                  <allnews  v-show="show===2"/>
            </el-container>
            <GlobalKanban v-if="show === 3"/>

            <el-container v-show="!isPlatform">
                <iframe allowfullscreen v-show="show===0" :src="appUrl" frameborder="0" style="width:100%"></iframe>
                  <Question :list="list" @question="question" v-show="show===1"/>
                  <allnews :biaoji="biaoji" :obj="obj" :id="id" :newlist="newlist" v-show="show===2"/>
            </el-container>
        </el-container>

        <!-- 消息弹窗 -->
        <div v-show="showMessageDialog" :class="{'message_dialog': true, 'message_dialog_enter': showEnter}">
            <div class="close_icon">
                <i class="el-icon-info" style="color:#e6a23c;float:left"></i>
                <i class="el-icon-close" @click="handleCloseDialog"></i>
            </div>
            <div>
                <div class="msg-title-container">
                    <h3>{{ messageData.title }}</h3>
                    <el-checkbox v-model="isCheck" @change="checkChange">今日不再弹出</el-checkbox>
                </div>
                <div>
                    <p>
                        {{ messageData.content }}
                    </p>
                    <img v-if="messageData.img" :src="messageData.img" alt="">
                </div>
                <div class="msg-btn-container" v-if="messageData.extend&&messageData.extend.button">
                    <el-button @click="toDetail(messageData.extend.id,messageData.messageType)">{{messageData.messageType==21?'查看报警详情':'查看证书详情'}}</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { io } from 'socket.io-client'
import { reqNewsReadOne } from '@/api/newsCenter/newsCenter'
// import Cookies, { remove } from 'js-cookie'
import Cookies from 'js-cookie'

import { mapState, mapActions } from 'vuex'
import Header from './header.vue'
import Navbar from './navbar.vue'
import TagsNav from './tagsNav.vue'
import Question from '@/views/question/question'
import allnews from '@/views/newCenter/allsNews'
import GlobalKanban from '@/views/globalKanban/globalKanban'
// import CedQuestion from '@/views/question/cedQuestion.vue'

export default {
    name: 'home',
    components: {
        Header,
        Navbar,
        TagsNav,
        Question,
        allnews,
        GlobalKanban
        // CedQuestion,
    },

    data () {
        return {
            isPlatform: false,
            globalKanban: false,
            appUrl: '',
            url: '',
            show: 0,
            arr1: [],
            isQuestion: false,
            list: true,
            id: 0,
            biaoji: 0,
            newlist: [],
            obj: {},

            showMessageDialog: false,
            showEnter: false,
            messageData: {},
            clearTime: '',
            isCheck: false
        }
    },

    watch: {
        currentAppCode: {
            immediate: true,
            handler (v) {
                this.save()
                const token = Cookies.get('token')
                // const code = 'PLATFORM_SYSTEM_MANAGEMENT'
                if (v === 'SYSTEM_MANAGEMENT') {
                    /* 平台管理 */
                    this.isPlatform = true
                    this.show = 0
                } else if (v === 'ETM_MANAGEMENT') {
                    /* etm */
                    this.isPlatform = true
                    this.show = 0
                    const openNewWindow = () => {
                        const a = document.createElement('a')
                        a.setAttribute('href', this.currentApp.appUrl)
                        a.setAttribute('target', '_blank')
                        a.click()
                    }

                    openNewWindow()
                } else if (v === 'GLOBAL_KANBAN') {
                    this.show = 3
                } else {
                    this.appUrl = `${this.currentApp.appUrl}?from=platform&token=${token}&appCode=${v}`
                    this.isPlatform = false
                    this.show = 0
                    this.isQuestion = true
                    console.log(this.appUrl)
                }
            }
        },
        '$store.state.externalLink' (v) {
        },
    },

    computed: {
        ...mapState(['menuCollapse', 'currentApp', 'currentAppCode', 'applicationsData'])
    },

    mounted () {
        let wsClientId = sessionStorage.getItem('wsClientId')
        const obj = Cookies.get('checkDate') ? JSON.parse(Cookies.get('checkDate')) : null
        if (!wsClientId) {
            wsClientId = `${Date.now()}-${Math.random().toString().slice(2)}`
            sessionStorage.setItem('wsClientId', wsClientId)
        }
        console.log(obj)
        for (const key in obj) {
            if (new Date().getDate() > new Date(obj[key].date).getDate()) {
                delete obj[key]
            }
        }
        if (!obj || !obj[this.$store.state.user.sysUser.userId]) {
            this.originSocket()
        }
        window.onbeforeunload = () => {
            this.$utils.websocketFn().close()
        }
    },

    methods: {
        ...mapActions(['actionToggleMenu', 'actionCurrentAppSet', 'actionAppsData']),

        /* 原生 websocket */
        originSocket () {
            if (!window.WebSocket) return // 不支持websocket 就不弹窗
            let url = this.$utils.getDomain()
            let ws = 'ws'
            if (url.indexOf('https://') > -1) {
                ws = 'wss'
            }
            url = url.replace('http://', '').replace('https://', '')
            const userId = Cookies.get('userId')
            const token = Cookies.get('token')
            const wsClientId = sessionStorage.getItem('wsClientId')
            window.socket = new WebSocket(`${ws}://${url}/websocket/${userId}/${wsClientId}?token=${token}`)
            window.socket.addEventListener('open', (event) => {
                console.log('websocket open')
            })
            window.socket.addEventListener('message', (event) => {
                if (event && event.data && event.data !== 'null' && event.data !== null) {
                    this.openMessage(event.data)
                }
            })
            window.socket.addEventListener('close', (event) => {
                console.log('websocket close')
            })
            window.socket.addEventListener('error', (error) => {
                console.log('websocket error', error)
                this.$utils.websocketFn().close()
            })
        },

        /* 处理弹出告警信息 */
        openMessage (message) {
            const { messageData } = this
            message = JSON.parse(message)
            const { id, title, content, extend, messageType } = message
            if (messageData.id !== message.id) {
                const d = {
                    id,
                    title,
                    content,
                    extend,
                    messageType
                }
                if (extend && extend.query && extend.query.alarmData && extend.query.alarmData.imageUrl) {
                    d.img = extend.query.alarmData.imageUrl
                }
                this.messageData = d
                this.showMessageDialog = true
                setTimeout(() => {
                    this.showEnter = true
                }, 200)

                /* 30m 后自动关闭告警消息弹窗 */
                this.clearTime = setTimeout(() => {
                    this.handleCloseDialog()
                }, 1000 * 60)
            }
        },

        handleCloseDialog () {
            this.showMessageDialog = false
            this.showEnter = false

            clearTimeout(this.clearTime)

            this.markRead()
        },

        /* 标记已读 */
        markRead () {
            const { messageData: { id } } = this
            reqNewsReadOne([id])
                .then(() => {
                    // this.messageData = {}
                })
        },

        save () {
            if (this.currentAppCode === 'SYSTEM_MANAGEMENT') {
                this.$nextTick(() => {
                    this.$refs.tagNav.initTag()
                })
            }
        },
        change (id) {
            this.show = 1
            this.list = true
        },

        /* 点击切换应用 */
        appChange (code, id) {
            if (code === this.currentAppCode) {
                this.actionCurrentAppSet(Number(id))
                this.actionAppsData()
                    .then((result) => {
                        this.$router.push({ path: '/' })
                    })
                this.show = 0
            }
        },
        question (id, item) {
            // reqQuestionListDetail(id).then((res) => {
            //     this.obj = res.data
            // })
            this.show = 1
            this.list = false
        },
        news () {
            this.show = 2
        },
        newsDetail (biaoji, obj, newlist, id) {
            this.biaoji = biaoji
            this.obj = obj
            this.newlist = newlist
            this.id = id
            this.id = Number(this.id)
            this.show = 2
        },
        checkChange (val) {
            const id = this.$store.state.user.sysUser.userId
            const obj = Cookies.get('checkDate') ? JSON.parse(Cookies.get('checkDate')) : {}
            if (val) {
                if (!obj[id]) {
                    obj[id] = {}
                    obj[id].status = val
                    obj[id].date = new Date()
                    Cookies.set('checkDate', obj)
                }
                this.$utils.websocketFn().close()
            } else {
                if (obj[id]) {
                    delete obj[id]
                    Cookies.set('checkDate', obj)
                }
                this.originSocket()
            }
        },
        toDetail (id, type) {
            const obj = this.$store.state.applicationsData.filter((item) => {
                return item.code === 'SAFETY_MANAGEMENT'
            })
            if (type === 21) {
                window.open(obj[0].appUrl + 'safeOperation/breakAlarmInfo?isNewTag=true&id=' + id)
            } else if (type === 25) {
                window.open(obj[0].appUrl + 'certificateHome/certificate?isNewTag=true&id=' + id)
            }
        },
    }
}
</script>

<style lang="scss" scope>
@import "@/styles/layout/layout.scss";
.msg-title-container{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    h3{
        margin: 0;
    }
}
.msg-btn-container{
    text-align: center;
}
</style>

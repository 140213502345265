<template>
    <div class="layout_nav_sidebar">
        <div :class="menuCollapse ? 'icon_uf' : 'icon_f'">
            <i @click="handleMenuFold" style="font-size:22px" :class="menuCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
        </div>
        <el-menu
            unique-opened
            :collapse-transition="false"
            :collapse="menuCollapse"
            :default-active="active"
            :default-openeds="open"
            @select="handleSelect"
        >
            <Menu :menuTree="currentApp.menuTree"/>
            <!-- <li style="font-size:15px;margin-top:30px;text-align:center;cursor: pointer;" @click="toQuestion">联系产品</li> -->
        </el-menu>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Menu from './menu'

export default {
    components: {
        Menu
    },

    data () {
        return {
            menu: [],
            open: [],
            active: ''
        }
    },

    computed: {
        ...mapState(['menuCollapse', 'currentApp']),
        ...mapState('user', ['roles']),
    },
    created () {

    },
    mounted () {
        this.initOpen()
    },
    methods: {
        ...mapActions(['actionToggleMenu']),

        initOpen () {
            const { path: rp, meta } = this.$route
            const openPath = rp === '/' ? [] : rp.slice(1).split('/')
            this.$nextTick(() => {
                this.open = openPath
                /* 利用路由中配置active */
                if (meta && meta.active) {
                    this.active = meta.active
                    return
                }
                if (openPath.length > 2) {
                    this.active = openPath[2]
                } else {
                    this.active = openPath[1]
                }
            })
        },

        /* 处理菜单的折叠 */
        handleMenuFold () {
            this.actionToggleMenu()
        },

        routerSkip (name) {
            this.$router.push({ name })
        },

        handleSelect (val, select) {
            this.open = select
        },
        toQuestion () {
            this.$router.push({ name: 'question' })
        }
    }
}
</script>

<style lang="scss" scoped>
.icon_f, .icon_uf{
    border-right:solid 1px #e6e6e6
}
.icon_f{
    text-align:right;
    padding: 4px 18px 0 0;
}
.icon_uf{
    text-align:center;
    padding: 4px 0 0 0;
}
</style>

<template>
    <div class="alarmTrend flex1 flexGrow1">
        <div class="cardStyle" id="alarmTrend" style="height:249px;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
    name: 'sevenDayAlarmTrend', // 近七天告警趋势
    data () {
        return {
        }
    },
    mounted () {
        this.alarmTrend()
    },
    methods: {
        // 近七天告警趋势
        alarmTrend () {
            const alarmTrend = echarts.init(document.getElementById('alarmTrend'))
            const option = {
                title: {
                    text: '近7天告警趋势',
                    left: '20',
                    textStyle: {
                        fontSize: '16'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['2020', '2021'],
                    right: '20'
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['4-20', '4-21', '4-22', '4-23', '4-24', '4-25', '4-26'],
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                            color: '#E9E9E9'
                        }
                    },
                },
                series: [
                    {
                        name: '2020',
                        type: 'bar',
                        data: [18, 23, 21, 10, 13, 23, 13],
                        itemStyle: {
                            color: '#198FD8'
                        },
                        barWidth: '20',

                    },
                    {
                        name: '2021',
                        type: 'bar',
                        data: [19, 23, 11, 12, 13, 28, 18],
                        itemStyle: {
                            color: '#33DDE6'
                        },
                        barWidth: '20',
                        barGap: '0'
                    }
                ]
            }
            alarmTrend.setOption(option)
            // 自动渲染echarts
            window.addEventListener('resize', () => {
                alarmTrend.resize()
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/demo.scss';
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/layout/home'
import Login from '@/views/login/login.vue'
import NotFound from '@/views/notFound/notFound.vue'
import Welcome from '@/views/welcome/welcome.vue'
import Question from '@/views/question/question.vue'
import CedQuestion from '@/views/question/cedQuestion.vue'
import allsNews from '@/views/newCenter/allsNews.vue'

import system from './modules/system'
import platform from './modules/platform'
import metadataManagement from './modules/metadataManagement'
/*
利用push 跳转路由，当重复点击跳转同一个路由地址时会报错 NavigationDuplicated 一下为处理该报错
Vue-router在3.1之后把$router.push()方法改为了Promise
*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    /* 添加 catch 不抛出错误 */
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const baseRouter = [
    { path: '/login', name: 'login', component: Login },
    { path: '/404', component: NotFound }
]

const root = {
    path: '/',
    component: Home,
    children: [
        { path: '', component: Welcome },
        { path: 'question', name: 'question', component: Question },
        { path: 'question/:id', name: 'cedQuestion', component: CedQuestion },
        { path: 'allsNews', name: 'allsNews', component: allsNews }]
}
// const anyError = { path: '*', component: NotFound }

const routes = [
    {
        path: '/externalLinkMenu',
        name: 'externalLinkMenu',
        title: '内联外部链接',
        component: Home,
        meta: { menu: true },
        children: [
            {
                path: 'externalLink',
                name: 'externalLink',
                title: '外部链接',
                meta: { menu: true },
                component: () => import('@/views/externalLink/externalLink.vue')
            },
        ]
    },
    ...system,
    ...platform,
    ...metadataManagement,
]

const router = new VueRouter({
    routes: baseRouter
})

/**
 * @des 根据权限生成路由
 */
const permitRouters = function (names) {
    const recursion = (arr) => {
        const tempModels = []
        arr.forEach(({ name, path, component, title, meta, children }) => {
            if (names.includes(name)) {
                const tempModel = {}
                tempModel.path = path
                tempModel.name = name
                meta = meta || {}
                meta.title = title
                tempModel.meta = meta

                tempModel.component = component
                if (children && children.length) {
                    tempModel.children = recursion(children)
                }
                tempModels.push(tempModel)
            }
        })
        return tempModels
    }

    return recursion(routes)
}

/* 获取菜单中的 pageComponent 集合 */
const getCurrentAppNames = (menuTree) => {
    const names = []
    const getName = (arr) => {
        arr.forEach(e => {
            names.push(e.pageComponent)
            if (e.children ? e.children.length : false) getName(e.children)
        })
    }
    getName(menuTree)

    return names
}

export const setRouter = (currentApp) => {
    const permitR = permitRouters(getCurrentAppNames(currentApp.menuTree))
    permitR.unshift(root)
    if (permitR.length) {
        router.addRoutes(permitR)
    }
}

/* 动态更新路由 */
export const updateRouter = (currentApp) => {
    const permitR = permitRouters(getCurrentAppNames(currentApp.menuTree))
    permitR.unshift(...baseRouter, root)
    if (permitR.length) {
        const newRouter = new VueRouter({
            routes: permitR
        })

        router.matcher = newRouter.matcher
    }
}

export default router

<template>
    <div>
        <el-tabs v-model="activeName" closable @tab-click="routerSkip" @tab-remove="handleClose">
            <el-tab-pane
                :key="item.name"
                v-for="(item) in tags"
                :label="item.title"
                :name="item.name"
            >
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

export default {
    name: 'TagsNav',
    data () {
        return {
            activeName: 'index',
            currentTag: '',
            left: 0,
            tags: [{ title: '首页', name: 'index' }],
            // menuLeft: 0,
            // menuTop: 0,
            menuVisible: false,
            menuCurrent: ''
        }
    },

    mounted () {
    },

    watch: {
        $route: {
            immediate: true,
            handler (route) {
                this.addTag(route)
            }
        },

        menuVisible (v) {
            if (v) {
                /* 点击任何地方 关闭menu */
                document.body.addEventListener('click', () => {
                    this.menuVisible = false
                })
            } else {
                document.body.removeEventListener('click', () => {})
            }
        }
    },

    methods: {

        routerSkip (name) {
            if (typeof name === 'object') {
                name = name.name
            }
            if (name === 'index') {
                this.$router.push({ path: '/' })
            } else {
                this.$router.push({ name })
            }
        },

        addTag (route) {
            // console.log(route)
            const { tags } = this
            if (!tags.some(val => val.name === route.name)) {
                /* 只显示菜单中有的路由 */
                if (route.meta.menu) {
                    this.tags.push({ title: route.meta.title, name: route.name })
                }
            }
            this.currentTag = route.name
            if (route.name) {
                this.activeName = route.name
            } else {
                this.activeName = 'index'
            }
        },

        handleClose (name, type) {
            const { tags, currentTag } = this

            type = type || 'current'

            let reTags = []

            if (type === 'current') {
                /* 过滤删除当前选择项 */
                reTags = tags.filter(v => name !== v.name)
                if (name === currentTag && reTags.length) {
                    this.routerSkip(reTags[reTags.length - 1].name)
                }
            } else if (type === 'other') {
                /* 删除其它标签 */
                reTags = tags.filter(v => name === v.name)
                this.routerSkip(reTags[0].name)
            }

            this.tags = reTags

            if (!reTags.length) {
                this.$router.push({ path: '/' })
            }
        },

        handleCloseCurrent () {
            const { menuCurrent } = this
            this.handleClose(menuCurrent)
        },

        handleCloseOther () {
            const { menuCurrent } = this
            this.handleClose(menuCurrent, 'other')
        },

        initTag () {
            this.tags = [{ title: '首页', name: 'index' }]
        },
    }
}
</script>

<style lang="scss">
</style>

import { Message } from 'element-ui'

import store from '@/store/store'
import { reqUploadFile } from '@/api/common/common'

/**
 * 判断当前环境域名,返回对应的接口地址
 * params  需要的参数  以对象形式传入
 * commonPre [ Object ] : { commonPre: '/xxx' }  不需要前缀可设置 { commonPre: '' }
 * */
export const getDomain = (params) => {
    let domain = ''
    // const product = 'https://phm-api.ivystar.com.cn:8443'
    const product = 'https://ehs-api.ivystar.com.cn:9999'
    /* 设备开发环境接口 */
    const dev1 = 'http://192.168.0.3:9999'
    // const dev1 = 'http://192.168.0.14:9999'

    const url = location.href
    if (window.PAGE_URL) {
        domain = window.API_URL
    } else if (url.indexOf('ivystar.com') > -1) {
        /* 线上环环境 */
        domain = product
    } else if (url.indexOf('139.9.246.118') > -1) {
        domain = 'http://139.9.246.118:9999'
    } else {
        domain = dev1
    }

    if (params && typeof params === 'object') {
        /* 有其它前缀时 */
        const { commonPre } = params
        domain += commonPre || ''
    } else {
        /* 默认是加 /admin */
        domain += '/admin'
    }
    return domain
}

export function formatToQueryString (obj) {
    // format the object as a queryString
    const temp = []
    const allKeys = Object.keys(obj)

    allKeys.forEach(v => {
        if (obj[v]) temp.push(`${v}=${obj[v]}`)
    })

    return temp.join('&')
}

export function formatTime (dateString, type) {
    if (dateString) {
        type = type || 'precisionM'
        const date = new Date(dateString)
        const y = date.getFullYear(); let m = date.getMonth() + 1; const d = date.getDate(); let h = date.getHours(); let minute = date.getMinutes(); let s = date.getSeconds()
        if (m < 10) m = '0' + m
        if (h < 10) h = '0' + h
        if (minute < 10) minute = '0' + minute
        if (s < 10) s = '0' + s

        const commandObj = {
            precisionM: function () {
                return y + '-' + m + '-' + d + '-' + ' ' + h + ':' + m
            },
            dateZH: function () {
                const temp = dateString.split(' ')[0].split('-')
                return `${temp[0]}年${temp[1]}月${temp[2]}日`
            }
        }

        return commandObj[type]()
    } else {
        return ''
    }
}

/**
 * @param {file: File} 选中的文件
 */
export const uploadFile = (file) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('directory', 'TARGET_MANAGEMENT')
    formData.append('bucket', 'ivy-security-bucket')
    return new Promise((resolve, reject) => {
        // 上传接口请求
        reqUploadFile(formData)
            .then((result) => {
                resolve(result)
            }).catch((err) => {
                reject(err)
            })
    })
}

/**
 * @des 上传单个图片
 * @param {file: File} 选中的文件
 */
export const uploadPhoto = (file) => {
    const formData = new FormData()
    formData.append('对应的字段名称', file)
    return new Promise((resolve, reject) => {
        // 上传接口请求
    })
}

/* 获取本地文件 */
export const getFileFromLocal = (callback, inputId = 'cusSelectPhoto') => {
    let inputElement = document.querySelector(`#${inputId}`)

    if (inputElement) {
        inputElement.parentNode.removeChild(inputElement)
    }
    inputElement = document.createElement('input')
    inputElement.setAttribute('id', inputId)
    inputElement.setAttribute('type', 'file')
    inputElement.setAttribute('style', 'display:none')
    document.body.appendChild(inputElement)
    inputElement.addEventListener('change', (e) => {
        const files = e.target.files
        if (/\.txt$/.test(files[0].name)) {
            Message({ message: '不支持txt文件上传', type: 'error', duration: 2000 })
        } else if (files[0].size < 52428800) {
            callback(e.target.files)
        } else {
            Message({ message: '文件不超过50M', type: 'error', duration: 2000 })
        }
    })
    inputElement.click()
}

export const tagADownload = (href) => {
    const a = document.createElement('a')
    a.href = href
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

/* 导出 */
export const handleExport = (url, pre) => {
    const href = `${getDomain({ commonPre: pre || '' })}${url}`
    tagADownload(href)
}

/**
 * @des 下载模板
 * @param {*} name 模板名称
 */
export const downloadTemplate = (name) => {
    const href = `${getDomain({ commonPre: '' })}/res/excel/${name}.xlsx`
    tagADownload(href)
}

const downloadFileUrl = 'https://ivy-security-bucket.obs.cn-southwest-2.myhuaweicloud.com:443/'

/**
 * @des 下载文件
 * name 模板名称 host 域名地址
 */
export const downloadFile = (name, host) => {
    host = host || downloadFileUrl
    const href = `${host}${name}`
    tagADownload(href)
}
export const getDownloadFileUrl = (name, host) => (`${host || downloadFileUrl}${name}`)

/* 获取所有的租户 */
export async function getAllTenantList () {
    const tenantList = store.state.tenantList
    if (tenantList.length) {
        return tenantList
    } else {
        await store.dispatch('actionGetTenantList')
        return store.state.tenantList
    }
}

/**
 * @des websocket 操作
 */
export const websocketFn = () => {
    const judge = !!window.socket
    return {
        close () {
            if (judge) {
                window.socket.close()
            }
        }
    }
}

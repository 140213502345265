import jsCookie from 'js-cookie'
import router from './router/router.js'
import store from './store/store'

router.onError((error) => {
    if (error.type === 'NO_MATCH') {
        router.push({ path: '/404' })
    }
})

router.beforeEach(async (to, from, next) => {
    const currentPath = to.path
    const authorization = jsCookie.get('Authorization')
    if (authorization) {
        if (currentPath === '/login') {
            // 登录页面不校验
            next()
        } else {
            // 判断菜单是否有了
            if (store.state.applicationsData.length === 0) {
                try {
                    await store.dispatch('actionAppsData')
                    next({ ...to, replace: true })
                } catch (error) {
                    next({ path: '/login' })
                    console.error('beforeEach catch error:', error)
                }
            } else {
                if (to.matched.length === 0) {
                    const err = new Error()
                    err.type = 'NO_MATCH'
                    next(err)
                    return
                }
                next()
            }
        }
    } else {
        if (currentPath === '/login') {
            next()
        } else {
            next({ path: '/login' })
        }
    }
})

<template>
    <div class="cus_search_wrap">
        <div style="display: flex">
            <div style="flex: 1">
                <el-form
                    :inline="true"
                    :model="formData"
                    :rules="formRules"
                    ref="searchForm"
                >
                    <el-form-item
                        v-for="item in currentSearchOptions"
                        :key="item.key"
                        :label="withoutLabel ? '' : item.label"
                        :prop="item.key"
                        :style="widthStyle"
                    >
                        <el-input
                            :style="`width: ${contentWidth}px`"
                            v-if="item.type === 'input'"
                            clearable
                            v-model="formData[item.key]"
                            :placeholder="withoutLabel ? item.label : '请输入'"
                            :disabled="item.disabled"
                        ></el-input>
                        <el-select
                            :style="`width: ${contentWidth}px`"
                            v-else-if="item.type === 'select'"
                            :multiple="!!item.multiple"
                            clearable
                            v-model="formData[item.key]"
                            :placeholder="withoutLabel ? item.label : '请选择'"
                            @change="select"
                        >
                            <el-option
                                v-for="option in item.options"
                                :key="option.key"
                                :label="option.label"
                                :value="option.value"
                            ></el-option>
                        </el-select>
                        <el-date-picker
                            clearable
                            :style="`width: ${contentWidth}px`"
                            v-else-if="
                                item.type === 'date' ||
                                    item.type === 'datetime' ||
                                    item.type === 'year' ||
                                    item.type === 'month'
                            "
                            v-model="formData[item.key]"
                            :type="item.type"
                            :placeholder="withoutLabel ? item.label : '请选择'"
                            :value-format="dateType(item.type)"
                        >
                        </el-date-picker>
                        <el-date-picker
                            clearable
                            :style="`width: ${contentWidth}px`"
                            v-else-if="item.type === 'dateTimeRange'"
                            v-model="formData[item.key]"
                            value-format="yyyy-MM-dd hh:mm:ss"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                        <el-date-picker
                            clearable
                            :style="`width: ${contentWidth}px`"
                            v-else-if="item.type === 'dateRange'"
                            type="daterange"
                            v-model="formData[item.key]"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                        <el-cascader
                            clearable
                            :style="`width: ${contentWidth}px`"
                            v-else-if="item.type === 'cascade'"
                            v-model="formData[item.key]"
                            :show-all-levels="false"
                            :options="item.options"
                            :placeholder="withoutLabel ? item.label : '请选择'"
                            :props="{
                                checkStrictly: true,
                                value: item.value || 'id',
                                label: item.name || 'name',
                                emitPath: false
                            }"
                        >
                        </el-cascader>
                        <el-cascader
                            clearable
                            :style="`width: ${contentWidth}px`"
                            v-else-if="item.type === 'person'"
                            v-model="formData[item.key]"
                            :show-all-levels="false"
                            :options="item.options"
                            :placeholder="withoutLabel ? item.label : '请选择'"
                            :props="{
                                value: item.value || 'id',
                                label: item.name || 'name',
                                emitPath: false
                            }"
                        >
                        </el-cascader>
                    </el-form-item>
                </el-form>
            </div>
            <div class="part-btn" style="width:218px;position: relative;top:1px;display:flex; box-sizing: border-box;">
                <div style="position:absolute; bottom: 25px;">
                    <el-button type="primary" @click="handleSearch" style="margin-left:8px">查询</el-button>
                    <el-button  style="background-color: white" plain v-if="withReset"  @click="handleReset">重置</el-button>
                    <span class="more_btn" @click="handleSearchMore" v-if="showMoreBtn">{{ more ? '收起' : '更多' }} <em :class="more ? 'm-down' : 'm-up'"></em></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    /* 搜索 */
    name: 'SearchWrap',
    props: {
        /** 控制每一条搜索项宽度，不传就直接顺序排列 */
        width: {
            type: [Number, String],
            default: () => 0
        },
        withoutLabel: {
            type: Boolean,
            default: false
        },
        labelWidth: {
            type: Number,
            default: () => 100
        },
        /* 是否拥有重置 */
        withReset: {
            type: Boolean,
            default: false
        },
        searchOptions: {
            type: Array,
            /**
             * 输入框示例：[{ type: input, label:'搜索内容名称', key: '后端需要的字段' }]
             * 下拉框示例：[{ type: select, label:'搜索内容名称', key: '后端需要的字段', options: [{ key: value }] }]
             */
            default: () => []
        },
        /** @des 搜索按钮是否换行 */
        inline: {
            type: Boolean,
            default: false
        },
        /* 输入框，下拉框等内容输入组件宽度 */
        contentWidth: { type: Number, default: () => 160 }
    },

    data () {
        return {
            formData: {},
            /** @des 需要时添加 */
            formRules: {},
            currentWidth: '',
            departVisible: false,
            currentSearchOptions: [],
            showMoreBtn: false,
            more: false,
        }
    },

    created () {
        const { width } = this
        this.getWidthStyle(width)
        this.createFormData()
    },

    methods: {
        /* 处理日期的格式 */
        dateType (type) {
            const commanObj = {
                date: 'yyyy-MM-dd',
                datetime: 'yyyy-MM-dd hh:mm:ss',
                year: 'yyyy',
                month: 'yyyy-MM'
            }
            return commanObj[type] || commanObj.datetime
        },

        getWidthStyle (width) {
            if (width) {
                width = `width:${width}px`
            } else {
                width = ''
            }
            this.widthStyle = width
        },

        setSearchOption () {
            const { searchOptions, more } = this
            const len = searchOptions.length
            let n = len > 2 ? 2 : len
            const arr = []
            if (more) {
                n = len
            }
            for (let i = 0; i < n; i++) {
                arr.push(searchOptions[i])
            }

            this.currentSearchOptions = arr
        },

        createFormData () {
            const { searchOptions } = this
            if (searchOptions.length > 2) {
                this.showMoreBtn = true
            }
            const data = {}
            searchOptions.forEach(({ key, default: defaultValue }) => {
                data[key] = defaultValue || ''
                if (/Range/.test(key)) this.getWidthStyle(500)
            })
            this.formData = data

            this.setSearchOption()
        },

        handleSearch () {
            this.$refs.searchForm.validate(valid => {
                const { formData } = this
                const temp = {}
                Object.keys(formData).forEach(v => {
                    const d = formData[v]
                    if (d || d === 0 || typeof d === 'boolean') { temp[v] = formData[v] }
                })
                this.$emit('callback', {
                    type: 'search',
                    data: temp
                })
            })
        },
        select (item) {
            this.$emit('callback1', {
                type: 'selected',
                data: item
            })
        },

        handleReset () {
            this.$refs.searchForm.resetFields()
            this.$emit('callback', { type: 'reset' })
        },

        /* 展开折叠搜索项 */
        handleSearchMore () {
            const { more } = this
            this.more = !more
            if (more) {
                /* 当折叠时清空数据 */
                this.handleReset()
            }
            this.setSearchOption()
        },

    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variable.scss";
// .cus_search_wrap {
//     padding: 15px ;
//     border: 1px solid $c3;
//     background-color: $c1;
//     width: 850px !important;
//     float: left;
//     margin: 0 20px 2px 0;
//     box-shadow: 0px 2px 4px rgba(210, 210, 210, 0.5);
//     opacity: 1;
//     box-sizing: border-box;
// }
// .more_btn{
//     font-size: 14px;
//     color: #6887FF;
//     margin: 0 6px;
//     cursor: pointer;
// }
// .m-up, .m-down{
//     display: inline-block;
//     height: 22px;
//     width: 22px;
//     background: no-repeat;
//     background-position: center bottom;
//     position: relative;
//     left: -4px
// }
// .m-down{
//     background-image: url(~@/assets/image/m-up.png);
//     background-size: 20px auto;
//     top: 5px;
// }
// .m-up{
//     background-size: 11px auto;
//     top: 1px;
//     background-image: url(~@/assets/image/m-down.png);

// }
</style>

import axios from 'axios'
import { Message } from 'element-ui'
import Cookie from 'js-cookie'
import { getDomain } from '@/utils/common'
import router from '@/router/router'

/**
 * @des 所有与业务相关的参数，直接以对象的形式传入；请求方式与url在api模块中添加
 * @des 返回的数据结构 { code: '0 表示成功 -1 表示失败', data: '结果', msg: '结果描述' }
 */

const httpService = axios.create({
    withCredentials: true,
    timeout: 200000,
})

httpService.interceptors.request.use((cfg) => {
    /**
     * commonPre  是否有公共前缀  用法参见 getDomain() f方法
     */
    const { url, method, params, data, commonPre } = cfg

    cfg.headers.Authorization = Cookie.get('Authorization') ? Cookie.get('Authorization') : sessionStorage.getItem('ivy-Authorization')

    /**
     * @des 处理url中带参数（例：/xx/:id/:type/xx/ -> /xx/1/food/xx/）
     */
    if (/:/.test(url)) {
        const RegExpObject = /:([a-z]+)/ig
        let result = ''
        let _url = url
        while ((result = RegExpObject.exec(url)) !== null) {
            const key = result[1]
            _url = _url.replace(`:${key}`, params[key])
            delete cfg.params[key]
        }
        cfg.url = _url
    }

    /**
     * @des 处理不同请求方式
     */
    if (method !== 'get') {
        if (!data) {
            cfg.data = params
            delete cfg.params
        }
    }

    if (process.env.NODE_ENV === 'development') {
        if (/mock/.test(url)) {
            console.warn('mock request:', params, data)
        }
    }

    /**
     * @des 没有配置反向代理，直接加地址
     */
    if (cfg.project === 'flow') {
        cfg.baseURL = 'http://192.168.0.3:8080'
    } else {
        cfg.baseURL = getDomain(Object.keys(cfg).includes('commonPre') ? { commonPre } : '')
    }

    return cfg
}, (error) => {
    console.error('请求发生错误：', error)
    return Promise.reject(error)
})

httpService.interceptors.response.use((res) => {
    const { config, data } = res

    if (process.env.NODE_ENV === 'development') {
        if (/mock/.test(config.url)) {
            console.warn('mock response:', data)
        }
    }

    /* 这个参数是在 api 文件里面的方法中传递的，和url同一级 */
    if (config.showSuccessMsg && data.code === 0) {
        Message({
            message: data.msg,
            type: 'success',
            duration: 2000
        })
    }

    /* excludeCode 是否不对code进行判断；因为有的接口返回并不规范，比如登录接口 */
    if (data.code !== 0 && !config.excludeCode) {
        /* 这里的错误，只做提示信息的统一处理，因为每一个地方的错误处理业务不同 */
        Message({ message: data.msg, type: 'error', duration: 2000 })

        console.error(`错误码：${data.code}`, res.data)
        return Promise.reject(res.data)
    }
    return res.data
}, (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            Message({ message: '身份过期 请重新登录', type: 'error', duration: 2000 })
            router.push({ path: '/login' })
        } else {
            Message({ message: error.response.data.msg, type: 'error', duration: 2000 })
        }
    }
    // Message({ message: '服务器错误', type: 'error', duration: 2000 })
    // console.error('服务器错误：', error)
    return Promise.reject(error.response)
})

/* 安全相关的应用 url 前缀 manage */
export const httpManage = (params) => {
    params.commonPre = '/manage'
    return httpService(params)
}

/* 设备公共前缀 */
export const httpEquipment = (params) => {
    params.commonPre = '/device'
    return httpService(params)
}

/* 设备管理---巡检管理前缀 */
export const httpInspection = (params) => {
    params.commonPre = '/inspection'
    return httpService(params)
}

/* PHM url 前缀 ivy-phm-iot */
export const httpPhm = (params) => {
    params.commonPre = '/ivy-phm-iot'
    return httpService(params)
}
/* PHM url 前缀 ivy-phm-view */
export const httpPhmView = (params) => {
    params.commonPre = '/ivy-phm-view'
    params.project = 'phmView'
    return httpService(params)
}

/* 流程前缀 */
export const httpFlow = (params) => {
    params.commonPre = ''
    params.project = 'flow'
    params.excludeCode = true
    return httpService(params)
}

/* 默认平台 url 前缀 admin */
export default httpService

<template>
  <div class="newsDetail">
      <header>
       <el-page-header @back="goBack"></el-page-header>
       <div>
         <button @click="preNews">上一条</button>
         <button @click="nextNews">下一条</button>
       </div>
     </header>
     <div class="news-detail" >
       <div class="news-header">
         <div class="news-title">{{obj.title}}</div>
         <div class="news-date">{{obj.publishTime}}</div>
       </div>
       <div class="news-warp">
         <!-- <div class="biaoti">尊敬的likemiddle@163.com：</div> -->
         <p>{{obj.content}}</p>
       </div>
       <div class="new-btn-container" v-if="obj.extend&&obj.extend.button">
           <el-button @click="toDetail(obj.extend.id,obj.messageType)">{{obj.messageType==21?'查看报警详情':'查看证书详情'}}</el-button>
       </div>
     </div>
  </div>
</template>

<script>
import { reqNewsReadOne } from '@/api/newsCenter/newsCenter'
import Cookie from 'js-cookie'
export default {
    name: 'newsDetail',
    props: {
        toDetailId: {
            type: Number
        },
        detail: {
            type: Object
        },
        leixing: {
            type: Number
        }
    },
    data () {
        return {
            id: 0, // 传给父组件作为标记点击详情时的标记
            list: [],
            index: '',
            obj: {},
            ids: [] // 消息列表状态为未读的id存进来在调用
        }
    },
    created () {
        // 获取本地当前类型的所有消息
        this.list = JSON.parse(window.sessionStorage.getItem('newsList'))
        for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].id === this.toDetailId) {
                this.index = i
                this.obj = this.list[i]
                console.log(this.obj)
                // console.log(this.index)
                if (this.obj.readStatus === 0) {
                    this.ids = []
                    this.ids.push(this.list[i].id)
                    // console.log(this.ids)
                    reqNewsReadOne(this.ids).then((res) => {
                        // console.log(res)
                        if (res.code === 0) {
                            this.$message.success('该条标记已读成功')
                        }
                    })
                }
                // break
            }
        }
    },
    methods: {
        goBack () {
            // this.$router.push({ name: 'allsNews' })
            this.$emit('changeBiaoJi', 0, this.leixing)
        },
        nextNews () {
            // 下一张
            if (this.index < this.list.length - 1) {
                this.index += 1
                this.obj = this.list[this.index]
                if (this.list[this.index].readStatus === 0) {
                    this.ids = []
                    this.ids.push(this.list[this.index].id)
                    reqNewsReadOne(this.ids).then((res) => {
                        // console.log(res)
                        if (res.code === 0) {
                            this.$message.success('该条标记已读成功')
                        }
                    })
                }
            } else {
                this.$message.success('已经是最后一条了')
            }
        },
        preNews () {
            // 上一张
            if (this.index > 0) {
                this.index -= 1
                this.obj = this.list[this.index]
                if (this.list[this.index].readStatus === 0) {
                    this.ids = []
                    this.ids.push(this.list[this.index].id)
                    reqNewsReadOne(this.ids).then((res) => {
                        // console.log(res)
                        if (res.code === 0) {
                            this.$message.success('该条标记已读成功')
                        }
                    })
                }
            } else {
                this.$message.success('已经是第一条了')
            }
        },
        toDetail (id,type) {
            const token = Cookie.get('token')
            const obj = this.$store.state.applicationsData.filter((item) => {
                return item.code === this.obj.appCode
            })
            if(type == 21){
                window.open(obj[0].appUrl + 'safeOperation/breakAlarmInfo?isNewTag=true&id=' + id)
            }else if(type == 25){
                window.open(obj[0].appUrl + 'certificateHome/certificate?isNewTag=true&id=' + id)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.newsDetail{
   header {
    display: flex;
    height: 40px;
    align-items: center;
    border-bottom: 1px solid rgb(215,215,215);
    justify-content: space-between;
    button {
      width: 80px;
      padding: 5px;
      font-size: 16px;
      margin-right: 10px;
    }
  }
  .news-detail {
    width: 1100px;
    margin: 10px auto;
    height: 600px;
    border: 1px solid  rgb(215,215,215);
    .news-header {
      width: 800px;
      height: 70px;
      margin: 0 auto;
      border-bottom:1px solid rgb(215,215,215);
      .news-title {
        width: 100%;
        font-size: 16px;
        line-height: 30px;
        margin-top: 8px;
        text-align: center;
      }
      .news-date {
        width: 100%;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: rgb(170,170,170);
      }
    }
  }
  .news-warp {
    width: 800px;
    margin: 10px auto;
    font-size: 18px;
    p {
      text-indent: 35px;
    }
  }
  .new-btn-container{
      margin-top: 50px;
      text-align: center;
  }
}
::v-deep .layout_nav_sidebar{
    display: none;
}
</style>

<template>
    <div class="welcome">
        <img class="img" src="@/assets/image/welcome/5.png" alt="-_-">
    </div>
</template>

<script>
export default {
    /* 首页欢迎 */
    name: 'welcome'
}
</script>

<style lang="scss" scoped>
.welcome{
    display: flex;
      align-items: center;
      justify-content: center;
}
.img{
    max-width: 100%;
    max-height: 100%;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/theme/index.css'
import utils from './utils/index.js'
import './componentsAuto/index.js'
import './navigation.js'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.config.productionTip = false
Vue.prototype.$message = ElementUI.$message
Vue.use(ElementUI)
Vue.use(Viewer)
Viewer.setDefaults({
    Options: { inline: true, button: true, navbar: true, title: true, toolbar: true, tooltip: true, movable: true, zoomable: true, rotatable: true, scalable: true, transition: true, fullscreen: true, keyboard: true, url: 'data-source' }
})

/* 全局挂载 utils */
Vue.prototype.$utils = utils

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

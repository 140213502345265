<template>
  <div id="ced">
    <!-- 发起问题详情 -->
      <el-form>
         <el-page-header @back="goBack"></el-page-header>
        <div  class="warp">
          <header style="text-align:center;font-weight:600;border:1px solid #ccc;line-height:40px">问题发起 <em @click="upDown('1')" :class="up ? 'm-down' : 'm-up'"></em></header>
          <footer v-show="!up1" class="footer">
            <el-row :gutter="20">
              <el-col :span="6">
                <!-- <div class="item">
                  <label class="title">状态</label>
                  <el-input v-model="one" type="text" disabled />
                </div> -->
                <el-form-item label="状态">
                    <el-input v-model="one" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">业务功能</label>
                  <el-input v-model="item.businessFunctions" type="text" disabled />
                </div> -->
              </el-col>
              <el-col :span="6">
                  <!-- <div  class="item">
                  <label class="title">填写日期</label>
                    <el-input v-model="item.fillingTime" type="text" disabled />
                </div> -->
                  <el-form-item label="填写日期">
                    <el-input v-model="item.fillingTime" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">紧急程度</label>
                  <el-input v-model="item.emergency" type="text" disabled />
                </div> -->
              </el-col>
              <el-col :span="6">
                  <el-form-item label="发起人">
                    <el-input v-model="item.fillingUser" type="text" disabled />
                </el-form-item>
                  <!-- <div  class="item">
                    <label class="title">发起人</label>
                    <el-input v-model="item.fillingUser" type="text" disabled />
                </div> -->
                  <!-- <div  class="item">
                  <label class="title">期望完成日期</label>
                  <el-input v-model="item.completionDate" type="text" disabled />
                </div> -->
              </el-col>
                <el-col :span="6">
                    <el-form-item label="相关产品">
                    <el-input v-model="item.productCategory" type="text" disabled />
                </el-form-item>
                  <!-- <div  class="item">
                    <label class="title">相关产品</label>
                    <el-input v-model="item.productCategory" type="text" disabled />
                </div> -->
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <!-- <div class="item">
                  <label class="title">状态</label>
                  <el-input v-model="one" type="text" disabled />
                </div> -->
                    <el-form-item label="业务功能">
                    <el-input v-model="item.businessFunctions" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">业务功能</label>
                  <el-input v-model="item.businessFunctions" type="text" disabled />
                </div> -->
              </el-col>
              <el-col :span="6">
                  <!-- <div  class="item">
                  <label class="title">填写日期</label>
                    <el-input v-model="item.fillingTime" type="text" disabled />
                </div> -->
                    <el-form-item label="紧急程度">
                    <el-input v-model="item.emergency" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">紧急程度</label>
                  <el-input v-model="item.emergency" type="text" disabled />
                </div> -->
              </el-col>
              <el-col :span="6">
                    <el-form-item label="期望完成日期">
                    <el-input v-model="item.completionDate" type="text" disabled />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                <el-form-item label="问题需求描述">
                    <el-input type="textarea" v-model="item.equirementsDescribe" disabled />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="附图">
                      <viewer :images="imgUrls">
                                <img
                                      v-for="(src,index) in imgUrls"
                                      :src="src"
                                      :key="index"
                                      :onerror="errorImg"
                                      style="width:146px;height:146px;margin: 5px;border: 1px solid #ccc"
                                    >
                      </viewer>
                </el-form-item>
              </el-col>
            </el-row>
          </footer>
        </div>
         <div  class="warp">
        <header style="text-align:center;font-weight:600;border:1px solid #ccc;line-height:40px">问题响应 <em @click="upDown('2')" :class="up ? 'm-down' : 'm-up'"></em></header>
        <footer v-show="!up2" class="footer">
          <el-row :gutter="20">
             <el-col :span="6">
                 <el-form-item label="状态">
                    <el-input v-model="two" type="text" disabled />
                </el-form-item>
               <!-- <div class="item">
                 <label class="title">状态</label>
                 <el-input v-model="two" type="text" disabled />
               </div> -->
             </el-col>
             <el-col :span="6">
                 <el-form-item label="响应人">
                   <el-input v-model="item.responseUser" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">响应人</label>
                  <el-input v-model="item.responseUser" type="text" disabled />
               </div> -->
             </el-col>
               <el-col :span="6">
                   <el-form-item label="响应时间">
                    <el-input v-model="item.responseTime" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">响应时间</label>
                  <el-input v-model="item.responseTime" type="text" disabled />
               </div> -->
               </el-col>
               <el-col :span="6">
                   <el-form-item label="指定处理人">
                    <el-input v-model="item.designatedHandler" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">指定处理人</label>
                  <el-input v-model="item.designatedHandler" type="text" disabled />
               </div> -->
              </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
                <el-form-item label="处理建议">
                    <el-input v-model="item.treatmentDescribe" type="textarea" disabled />
                </el-form-item>
               <!-- <div class="item">
                 <label class="title">处理建议</label>
                 <el-input v-model="item.treatmentDescribe" type="text" disabled />
               </div> -->
            </el-col>
          </el-row>
        </footer>
      </div>
         <div  class="warp">
        <header style="text-align:center;font-weight:600;border:1px solid #ccc;line-height:40px">问题处理 <em @click="upDown('3')" :class="up ? 'm-down' : 'm-up'"></em></header>
        <footer  v-show="!up3" class="footer">
          <el-row :gutter="20">
             <el-col :span="6">
                 <el-form-item label="状态">
                    <el-input v-model="three" type="text" disabled />
                </el-form-item>
               <!-- <div class="item">
                 <label class="title">状态</label>
                 <el-input v-model="three" type="text" disabled />
               </div> -->
               <!-- <div class="item">
                 <label class="title">是否遗留问题</label>
                 <el-input v-model="item.problem" type="text" disabled />
               </div> -->
             </el-col>
             <el-col :span="6">
                 <el-form-item label="完成人">
                   <el-input v-model="item.involvedUser" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                 <label class="title">完成人</label>
                  <el-input v-model="item.involvedUser" type="text" disabled />
               </div> -->
             </el-col>
             <el-col :span="6">
                 <el-form-item label="完成时间">
                   <el-input v-model="item.completionTime" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">完成时间</label>
                  <el-input v-model="item.completionTime" type="text" disabled />
               </div> -->
             </el-col>
             <el-col :span="6">
                 <el-form-item label="处理结果">
                  <el-input v-model="item.resultDescribe" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">处理结果</label>
                  <el-input v-model="item.resultDescribe" type="text" disabled />
               </div> -->
             </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
                <el-form-item label="是否遗留问题">
                 <el-input v-model="item.problem" type="text" disabled />
                </el-form-item>
                <!-- <div class="item">
                 <label class="title">是否遗留问题</label>
                 <el-input v-model="item.problem" type="text" disabled />
               </div> -->
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
                <el-form-item label="遗留问题处理意见">
                    <el-input v-model="item.problemsDescribe" type="textarea" disabled />
                </el-form-item>
                <!-- <div class="item">
                 <label class="title">遗留问题处理意见</label>
                 <el-input v-model="item.problemsDescribe" type="textarea" disabled />
               </div>
                 <div class="item">
                 <label class="title">附图</label>
                <uploadFileWrap :imgUrls="imgUrlsTwo" :dis="dis"/>
               </div> -->
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="附图">
                      <viewer :images="imgUrlsTwo">
                                <img
                                      v-for="(src,index) in imgUrlsTwo"
                                      :src="src"
                                      :key="index"
                                      :onerror="errorImg"
                                      style="width:146px;height:146px;margin: 5px;border: 1px solid #ccc"
                                    >
                      </viewer>
                </el-form-item>
            </el-col>
          </el-row>
        </footer>
      </div>
         <div class="warp">
        <header style="text-align:center;font-weight:600;border:1px solid #ccc;line-height:40px">问题验收 <em @click="upDown('4')" :class="up ? 'm-down' : 'm-up'"></em></header>
        <footer v-show="!up4" class="footer">
          <el-row :gutter="20">
             <el-col :span="6">
                     <el-form-item label="状态">
                    <el-input v-model="four" type="text" disabled />
                </el-form-item>
               <!-- <div class="item">
                 <label class="title">状态</label>
                 <el-input v-model="four" type="text" disabled />
               </div> -->
             </el-col>
             <el-col :span="6">
                     <el-form-item label="验收人">
                   <el-input v-model="item.acceptanceUser" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                 <label class="title">验收人</label>
                  <el-input v-model="item.acceptanceUser" type="text" disabled />
               </div> -->
             </el-col>
             <el-col :span="6">
                     <el-form-item label="验收日期">
                     <el-input v-model="item.acceptanceTime" type="text" disabled />
                </el-form-item>
                <!-- <div  class="item">
                  <label class="title">验收日期</label>
                  <el-input v-model="item.acceptanceTime" type="text" disabled />
               </div> -->
             </el-col>
                <el-col :span="6">
                        <el-form-item label="验收结论">
                   <el-input  v-model="four" type="text" disabled />
                </el-form-item>
             </el-col>
          </el-row>
               <el-row :gutter="20">
               <el-col :span="24">
                    <el-form-item label="验收意见">
                   <el-input  v-model="item.acceptanceDescribe" type="textarea" disabled />
                </el-form-item>
                  <!-- <div  class="item">
                  <label class="title">验收意见</label>
                  <el-input  v-model="item.acceptanceDescribe" type="text" disabled />
               </div> -->
              </el-col>
             </el-row>
              <el-row  :gutter="20">
               <el-col :span="24">
                       <el-form-item label="验收评价">
                    <el-rate disabled allow-half v-model="item.acceptanceEvaluation"></el-rate>
                </el-form-item>
                   <!-- <div class="item">
                 <label class="title">验收评价</label>
                  <el-rate disabled allow-half v-model="item.acceptanceEvaluation"></el-rate>
               </div> -->
              </el-col>
             </el-row>
        </footer>
      </div>
      </el-form>
  </div>
</template>

<script>
import { reqQuestionListDetail } from '@/api/question/question.js'
// import uploadFileWrap from '@/components/uploadFileWrap'
import dayjs from 'dayjs'
export default {
    name: 'cedQuestion',
    // components: { uploadFileWrap },
    props: {
        id: {
            type: String
        }
    },
    data () {
        return {
            item: {},
            status: '', // 当前状态
            one: '',
            two: '',
            three: '',
            four: '',
            imgUrls: [],
            imgUrlsTwo: [],
            dis: true,
            listOption2: [
                {
                    id: 0, label: '低', name: '低'
                },
                {
                    id: 1, label: '一般', name: '一般'
                },
                {
                    id: 2, label: '中', name: '中'
                },
                {
                    id: 3, label: '高', name: '高'
                }
            ],
            listOption4: [
                {
                    id: 0, label: '否', name: '否'
                },
                {
                    id: 1, label: '是', name: '是'
                },
            ],
            listOption3: [
                {
                    id: 4, label: '归档', name: '归档'
                },
                {
                    id: 3, label: '待优化', name: '待优化'
                },
                {
                    id: 5, label: '未完成', name: '未完成'
                },
            ],
            up: true,
            up1: false,
            up2: false,
            up3: false,
            up4: false,
            errorImg: ''
        }
    },
    created () {
        // console.log(this.$route)
        // const id = this.$route.query.id

        // console.log(this.obj)

        // this.listOption2.forEach((item, index) => {
        //     if (item.id === this.obj.emergency) {
        //         this.obj.emergency = this.listOption2[index].name
        //     }
        // })
        // this.listOption4.forEach((item, index) => {
        //     if (item.id === this.obj.problem) {
        //         this.obj.problem = this.listOption4[index].name
        //     }
        // })
        // if (this.obj.fillingTime) {
        //     this.obj.fillingTime = dayjs(this.obj.fillingTime).format('YYYY-MM-DD')
        // }
        // if (this.obj.completionDate) {
        //     this.obj.completionDate = dayjs(this.obj.completionDate).format('YYYY-MM-DD')
        // }
        // if (this.obj.completionDate) {
        //     this.obj.completionDate = dayjs(this.obj.completionDate).format('YYYY-MM-DD')
        // }
        // if (this.obj.responseTime) {
        //     this.obja.responseTime = dayjs(this.obj.responseTime).format('YYYY-MM-DD')
        // }
        // if (this.obj.completionTime) {
        //     this.obj.completionTime = dayjs(this.obj.completionTime).format('YYYY-MM-DD')
        // }
        // if (this.obj.acceptanceTime) {
        //     this.obj.acceptanceTime = dayjs(this.obj.acceptanceTime).format('YYYY-MM-DD')
        // }

        // // console.log(res.data)
        // this.item = this.obj
        // if (this.obj.status === 0) {
        //     // 待响应
        //     this.one = '待响应'
        // } else if (this.obj.status === 1) {
        //     // 已响应
        //     // 待处理
        //     this.one = '已响应'
        //     this.two = '待处理'
        // } else if (this.obj.status === 2) {
        //     // 已处理
        //     // 待验收
        //     this.one = '已响应'
        //     this.two = '已处理'
        //     this.three = '待验收'
        // } else if (this.obj.status > 2) {
        //     // 已验收
        //     this.one = '已响应'
        //     this.two = '已处理'
        //     this.three = '已验收'
        //     if (this.obj.status === 3) {
        //         this.four = '待优化'
        //     } else if (this.obj.status === 4) {
        //         this.four = '已归档'
        //     } else {
        //         this.four = '未完成'
        //     }
        // }
        // console.log(this.obj)
        // if (this.obj.problemImages) {
        //     let curUrl = []
        //     const imgUrl = []
        //     const downloadUrl = 'https://ivy-security-bucket.obs.cn-southwest-2.myhuaweicloud.com:443/'
        //     const directory = 'HIDDEN_DANGER_TROUBLESHOOTING'
        //     curUrl = this.obj.problemImages.split(',')
        //     curUrl.forEach((v) => {
        //         imgUrl.push({
        //             fileUrl: downloadUrl + directory + v,
        //             fileName: v.slice(19)
        //         })
        //     })
        //     this.imgUrls = imgUrl
        // }
        // if (this.obj.resultImages) {
        //     let curUrl = []
        //     const imgUrl = []
        //     const downloadUrl = 'https://ivy-security-bucket.obs.cn-southwest-2.myhuaweicloud.com:443/'
        //     const directory = 'HIDDEN_DANGER_TROUBLESHOOTING'
        //     curUrl = this.obj.resultImages.split(',')
        //     curUrl.forEach((v) => {
        //         imgUrl.push({
        //             fileUrl: downloadUrl + directory + v,
        //             fileName: v.slice(19)
        //         })
        //     })
        //     this.imgUrlsTwo = imgUrl
        // }
        reqQuestionListDetail(Number(this.id)).then((res) => {
            // console.log(res)
            // 现场照片回填
            if (res.data.problemImages) {
                // let curUrl = []
                // const imgUrl = []
                // const downloadUrl = 'https://ivy-security-bucket.obs.cn-southwest-2.myhuaweicloud.com:443/'
                // const directory = 'HIDDEN_DANGER_TROUBLESHOOTING'
                // curUrl = res.data.problemImages.split(',')
                // curUrl.forEach((v) => {
                //     imgUrl.push({
                //         fileUrl: downloadUrl + directory + v,
                //         fileName: v.slice(19)
                //     })
                // })
                this.imgUrls = res.data.problemImages.split(',')
            } else {
                this.imgUrls = []
            }
            if (res.data.resultImages) {
                this.imgUrlsTwo = res.data.resultImages.split(',')
            } else {
                this.imgUrlsTwo = []
            }
            this.listOption2.forEach((item, index) => {
                if (item.id === res.data.emergency) {
                    res.data.emergency = this.listOption2[index].name
                }
            })
            this.listOption4.forEach((item, index) => {
                if (item.id === res.data.problem) {
                    res.data.problem = this.listOption4[index].name
                }
            })
            if (res.data.fillingTime) {
                res.data.fillingTime = dayjs(res.data.fillingTime).format('YYYY-MM-DD')
            }
            if (res.data.completionDate) {
                res.data.completionDate = dayjs(res.data.completionDate).format('YYYY-MM-DD')
            }
            if (res.data.completionDate) {
                res.data.completionDate = dayjs(res.data.completionDate).format('YYYY-MM-DD')
            }
            if (res.data.responseTime) {
                res.data.responseTime = dayjs(res.data.responseTime).format('YYYY-MM-DD')
            }
            if (res.data.completionTime) {
                res.data.completionTime = dayjs(res.data.completionTime).format('YYYY-MM-DD')
            }
            if (res.data.acceptanceTime) {
                res.data.acceptanceTime = dayjs(res.data.acceptanceTime).format('YYYY-MM-DD')
            }

            // console.log(res.data)
            this.item = res.data
            if (res.data.status === 0) {
                // 待响应
                this.one = '待响应'
            } else if (res.data.status === 1) {
                // 已响应
                // 待处理
                this.one = '已响应'
                this.two = '待处理'
            } else if (res.data.status === 2) {
                // 已处理
                // 待验收
                this.one = '已响应'
                this.two = '已处理'
                this.three = '待验收'
            } else if (res.data.status > 2) {
                // 已验收
                this.one = '已响应'
                this.two = '已处理'
                this.three = '已验收'
                if (res.data.status === 3) {
                    this.four = '待优化'
                } else if (res.data.status === 4) {
                    this.four = '已归档'
                } else {
                    this.four = '未完成'
                }
            }
        })
    },
    methods: {
        goBack () {
            // this.$router.go(-1)
            this.$emit('back')
        },
        upDown (item) {
            // console.log(item)
            this.up = !this.up
            if (item === '1') {
                this.up1 = !this.up1
            } else if (item === '2') {
                this.up2 = !this.up2
            } else if (item === '3') {
                this.up3 = !this.up3
            } else {
                this.up4 = !this.up4
            }
        }
    },
}
</script>

<style lang="scss">
@import "@/styles/variable.scss";
#ced{
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #fff !important;
  height: 100%;
  .el-form {
    width: 100%;
    height: 100%;
  }
  .warp {
    .m-up, .m-down{
    display: inline-block;
    height: 22px;
    width: 22px;
    background: no-repeat;
    background-position: center bottom;
    position: relative;
    left: -4px
}
.m-down{
    // background-image: url(~@/assets/image/m-up.png);
    background-image: url('../../assets/image/m-up.png');
    background-size: 20px auto;
    top: 5px;
}
.m-up{
    background-size: 11px auto;
    top: 1px;
    background-image: url(~@/assets/image/m-down.png);

}
    margin-bottom: 20px;
    box-sizing: border-box;
    .footer{
    border: 1px solid #ccc;
    border-top: 0px;
    // height: 400px;
    height: auto;
    // padding: 0 10px;
    padding-right: 10px;
    .item {
      display: flex;
      margin: 15px auto;
      align-items: center;
      .title {
      text-align: right;
      padding-right: 8px;
      width: 140px;
    }

    }
    .el-rate__icon .el-icon-star-on {
      color: black !important;
    }
  }
  }
  .el-form-item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    box-sizing: border-box;
    margin-bottom: 10px !important;
  }
  .el-form-item__label {
    width: 130px !important;
  }
  .el-col-6 .el-form-item .el-form-item__label {
     width: 130px !important;
  }
   .el-col-24 .el-form-item .el-form-item__label{
    width: 100px !important;
  }
   .el-col-24 .el-form-item .el-form-item__content{
    width: 100% !important;
  }
   .el-col-12 .el-form-item .el-form-item__content{
    width: 100% !important;
  }
   .el-col-12 .el-form-item .el-form-item__label{
    width: 130px !important;
  }
    .el-col-6 .el-form-item .el-form-item__content{
    width: 100% !important;
  }
  .el-textarea__inner {
  height: auto !important;
}
}
</style>

import httpService from '../request'

// 适用企业
export function reqQuestionList (params) { return httpService({ url: '/productOpinions', method: 'get', params }) }
// 查询
export function reqQuestionListInfo (params) { return httpService({ url: '/productOpinions/info', method: 'get', params }) }
// 所属子系统
export function reqQuestionListDetail (id) { return httpService({ url: '/productOpinions/:id', method: 'get', params: { id } }) }

// 所属模块
export function reqQuestionListAdd (params) { return httpService({ url: '/productOpinions', method: 'post', params }) }

export function reqQuestionListOption (params) { return httpService({ url: '/productOpinions', method: 'put', params }) }

export function reqQuestionDel (id) { return httpService({ url: '/productOpinions/:id', method: 'delete', params: { id } }) }

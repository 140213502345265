import Home from '@/layout/home'

export default [
    {
        path: '/platform',
        name: 'platform',
        title: '平台管理',
        component: Home,
        meta: { menu: true },
        children: [
            {
                path: 'lessee',
                name: 'lessee',
                title: '租户管理',
                meta: { menu: true },
                component: () => import('@/views/platform/lessee/LesseeManage.vue')
            },
            {
                path: 'appGroupManage',
                name: 'appGroupManage',
                title: '应用组管理',
                meta: { menu: true },
                component: () => import('@/views/platform/appGroupManage/appGroupManage.vue')
            },
            {
                path: 'application',
                name: 'application',
                title: '应用管理',
                meta: { menu: true },
                component: () => import('@/views/platform/apply/application.vue')
            },
            {
                path: 'applicationMenu/:id',
                name: 'applicationMenu',
                title: '菜单管理',
                component: () => import('@/views/platform/apply/menu/menu.vue')
            },
            {
                path: 'formDesign',
                name: 'formDesign',
                title: '表单设计',
                meta: { menu: true },
                component: () => import('@/views/platform/formDesign/formDesign.vue')
            },
            {
                path: 'cedFormDesign/:type',
                name: 'cedFormDesign',
                title: '表单设计新增编辑',
                // meta: { menu: true },
                component: () => import('@/views/platform/formDesign/cedFormDesign.vue')
            },
        ]
    }
]

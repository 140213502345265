import store from '@/store/store'
// import common from '@/api/common/common'
/**
 * @param {string} dictionaryName 要使用的字典列表
 * @param {any} label 需要获取的某条数据的label
 */
export const dict = (...params) => {
    const reFn = (list) => {
        if (params.length > 1) {
            return list.find(v => v.value === params[1]) || { label: '' }
        } else {
            return list
        }
    }
    const commandObj = {
        status1: () => reFn([{ value: 0, label: '启用' }, { value: 1, label: '未启用' }]),
        deadlineStatus: () => reFn([{ value: false, label: '未到期' }, { value: true, label: '已到期' }]),
        perhapsList: () => reFn([
            { value: 1, label: '不会发生，但在极少数特定情况下可能发生' },
            { value: 2, label: '会发生少数伤害事件，但可能性极小' },
            { value: 3, label: '有一定的伤害事件发生可能性，不属于小概率事件' },
            { value: 4, label: '经常发生伤害事件' },
            { value: 5, label: '伤害事件发生的可能性极大，在任何情况下都会重复出现' },
        ]),
        timeResultList: () => reFn([
            { value: 1, label: '无伤亡，无损失' },
            { value: 2, label: '轻微受伤、间歇不舒服，损失在1万元以下' },
            { value: 3, label: '截肢、骨折、听力丧失、慢性病，损失在1万元以下' },
            { value: 4, label: '丧失劳动能力，损失在50万元以上' },
            { value: 5, label: '死亡，损失在100万元以上' },
        ]),
        possibilityList: () => reFn([
            { value: 0.1, label: '实际不可能' },
            { value: 0.2, label: '极不可能' },
            { value: 0.5, label: '很不可能' },
            { value: 1, label: '可能性小' },
            { value: 3, label: '可能，但不经常' },
            { value: 6, label: '相当可能' },
            { value: 10, label: '完全可以预料' },
        ]),
        degreeList: () => reFn([
            { value: 0.5, label: '非常罕见地暴露' },
            { value: 1, label: '每年几次暴露' },
            { value: 2, label: '每月一次暴露' },
            { value: 3, label: '每周一次或偶然暴露' },
            { value: 6, label: '每天工作时间内暴露' },
            { value: 10, label: '连续暴露' },
        ]),
        resultList: () => reFn([
            { value: 1, label: '无伤亡，损失在1万以下' },
            { value: 2, label: '轻微受伤、间歇不舒服，损失在1万以上' },
            { value: 7, label: '丧失劳动力、截肢、骨折、听力丧失、慢性病，损失在10万以上' },
            { value: 15, label: '3人以下死亡，或10人以下重伤，损失在100万以上' },
            { value: 40, label: '3人以上10人以下死亡，或10人以上50人以下重伤，损失在1000万以上' },
            { value: 100, label: '10人以上死亡，或50人以上重伤，损失在5000万以上' },
        ]),
        cycleList: () => reFn([
            { value: 1, label: '每日' }, { value: 2, label: '每周' }, { value: 3, label: '每月' },
            { value: 4, label: '每季度' }, { value: 5, label: '每半年' }, { value: 6, label: '每年' },
        ]),
        workTypeList: () => reFn([
            { value: 1, label: '动火作业' }, { value: 2, label: '有限空间作业' }, { value: 3, label: '高处作业' },
            { value: 4, label: '大型吊装作业' }, { value: 5, label: '预热器清堵作业' }, { value: 6, label: '篦冷机清理大块作业' },
            { value: 7, label: '清库作业' }, { value: 8, label: '交叉作业' }, { value: 9, label: '高温作业' },
            { value: 10, label: '其他高危作业' },
        ]),
        riskLevelList: () => reFn([
            { value: 1, label: '重大风险' }, { value: 2, label: '较大风险' }, { value: 3, label: '一般风险' }, { value: 4, label: '低风险' },
        ]),
        controlLevelList: () => reFn([
            { value: 1, label: '企业级' }, { value: 2, label: '部门级' }, { value: 3, label: '岗位级' },
        ]),
        qualifiedList: () => reFn([
            { value: 1, label: '合格' }, { value: 2, label: '不合格' }, { value: 3, label: '周期内未检查' }
        ]),
        isOrNotList: () => reFn([
            { value: 0, label: '否' }, { value: 1, label: '是' }
        ]),
        projectTypeList: () => reFn([
            { value: 0, label: '固定资产投资' }, { value: 1, label: '安全类技改项目' }
        ]),
        /* 分类1 */
        classifyList1: () => reFn([
            { value: 1, label: '基础管理' }, { value: 2, label: '资质证照' }, { value: 3, label: '安全生产管理机构及人员' }, { value: 4, label: '安全规章制度' },
            { value: 5, label: '安全培训教育' }, { value: 6, label: '安全投入' }, { value: 7, label: '相关方面管理' }, { value: 8, label: '重大危险源管理' },
            { value: 9, label: '个体防护装备' }, { value: 10, label: '职业健康' }, { value: 11, label: '应急管理' }, { value: 12, label: '隐患排查治理' },
            { value: 13, label: '事故报告（调查和处理）' }, { value: 14, label: '其他基础管理' }, { value: 15, label: '现场管理' }, { value: 16, label: '作业场所' },
            { value: 17, label: '设备设施' }, { value: 18, label: '防护（保险、信号等装置装备）' }, { value: 19, label: '原辅物料（产品）' }, { value: 20, label: '职业病危害' },
            { value: 21, label: '相关方作业' }, { value: 22, label: '安全技能' }, { value: 23, label: '个人防护' }, { value: 24, label: '作业许可' },
            { value: 25, label: '其他现场管理' },
        ]),
        classifyList2: () => reFn([
            { value: 1, label: '环保' }, { value: 2, label: '职业健康' }, { value: 3, label: '消防' },
            { value: 4, label: '安全' }, { value: 5, label: '特种设备' }, { value: 6, label: '隐蔽工程' },
        ]),
        induceTypeList: () => reFn([
            { value: 1, label: '物体打击' }, { value: 2, label: '车辆伤害' }, { value: 3, label: '机械伤害' }, { value: 4, label: '起重伤害' },
            { value: 5, label: '触电' }, { value: 6, label: '淹溺' }, { value: 7, label: '灼烫' }, { value: 8, label: '火灾' }, { value: 9, label: '高处坠落' },
            { value: 10, label: '坍塌' }, { value: 11, label: '冒顶片帮' }, { value: 12, label: '透水' }, { value: 13, label: '放炮' }, { value: 14, label: '火药爆炸' },
            { value: 15, label: '瓦斯爆炸' }, { value: 16, label: '锅炉爆炸' }, { value: 17, label: '容器爆炸' }, { value: 18, label: '其他爆炸' }, { value: 19, label: '中毒和窒息' },
            { value: 20, label: '其他伤害' }
        ]),
        classifyList3: () => reFn([
            { value: 1, label: '新建' }, { value: 2, label: '扩建' }, { value: 3, label: '改建' },
        ]),
        natureList: () => reFn([{ label: '员工', value: 1 }, { label: '合同方', value: 2 }]),
        /* 出题依据 */
        accordingList: () => reFn([
            { label: '法律', value: 1 }, { label: '法规', value: 2 }, { label: '部门规章', value: 3 }, { label: '规范性文件', value: 4 }, { label: '地方法律', value: 5 },
            { label: '地方法规', value: 10 }, { label: '国家标准', value: 6 }, { label: '行业标准', value: 7 }, { label: '地方标准', value: 8 }, { label: '企业管理制度', value: 9 }
        ]),
        /* 题目分类 */
        questionBelongList: () => reFn([
            { label: '安全', value: 1 }, { label: '法律', value: 2 }, { label: '消防法', value: 3 }, { label: '法规', value: 4 },
            { label: '制度', value: 5 }, { label: '环保', value: 6 }
        ]),
        /* 考试级别 */
        examLevelList: () => reFn([
            { label: '工程师', value: 1 }, { label: '技术人员', value: 2 }, { label: '初级工', value: 3 }, { label: '中级工', value: 4 },
            { label: '高级工', value: 5 }, { label: '不限级别', value: 6 }
        ]),
        /* 难度 */
        difficultyList: () => reFn([
            { label: '高', value: 1 }, { label: '中', value: 2 }, { label: '低', value: 3 }
        ]),
        /* 题型 */
        questionTypeList: () => reFn([
            { label: '单选题', value: 1 }, { label: '多选题', value: 2 }, { label: '判断题', value: 3 }
        ]),
        /* 分类 */
        examPaperList: () => reFn([
            { label: '生产', value: 1 }, { label: '安环', value: 2 }
        ]),
        /* 专业分类 */
        majorTypeList: () => reFn([
            { label: '安全环保知识', value: 1 }
        ]),
        /* 发布状态 */
        publishStatusList: () => reFn([
            { label: '未发布', value: 0 }, { label: '已发布', value: 1 }
        ]),
        /**
         * @dec  设备字典
         */
        /* 启停机状态 */
        pendingStatusList: () => reFn([
            { value: 1, label: '待处理' }, { value: 2, label: '排查中' }, { value: 3, label: '停机原因审核中' }, { value: 4, label: '完成' }
        ]),
        /* 缺陷池状态 */
        poolStatusList: () => reFn([
            { value: 1, label: '新增' }, { value: 2, label: '进入检修单' }, { value: 3, label: '忽略' }, { value: 4, label: '重新分配排查人' }, { value: 5, label: '已通知排查人' }
        ]),
        /* 来源 */
        sourceList: () => reFn([
            { value: 1, label: '手动新增' }, { value: 2, label: '巡检' }
        ]),
        /* 部件，零件 */
        BjLjList: () => reFn([
            { value: 1, label: '部件' }, { value: 2, label: '零件' }
        ]),
    }

    return commandObj[params[0]] ? commandObj[params[0]]() : []
}

/* 获取数据字典 */
export async function getDicData (code) {
    await store.dispatch('actionDataDic', code)
    return store.state.dataDictionary[code] || ''
}

/* 获取数据对应值 */
export function getDicValue (code, val) {
    const arr = store.state.dataDictionary[code]
    return arr ? arr.find(v => v.val === `${val}`) || {} : {}
}

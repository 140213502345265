<template>
    <div class="not_found">
        <p>404 没有发现该页面</p>
    </div>
</template>

<script>
export default {
    /* 404页面 */
    name: 'NotFound'
}
</script>

<style lang="scss" scoped>
.not_found {
    text-align: center;
    overflow: hidden;
    p{
        font-size: 16px;
        font-weight: 700;
    }
}
</style>

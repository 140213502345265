<template>
    <div class="flex2 flex rowColumn">
        <div>
            <div class="cardStyle" id="downtime" style="height:180px;"></div>
        </div>
        <div>
            <div class="cardStyle" id="malDowntime" style="height:180px;"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { reqNoPlanManage } from '@/api/globalKanban/globalKanban.js'
export default {
    name: 'downtimePercentage', // 停机占比
    data () {
        return {
        }
    },
    mounted () {
        this.getData()
        this.downtimeTrend()
        this.malDowntimeTrend()
    },
    methods: {
        getData () {
            /** 非计划停机时间 */
            reqNoPlanManage().then(res => {
                const noPlan = res.data.noPlan
                const sumTimes = res.data.sumTimes
                this.downtimeTrend(noPlan, sumTimes)
            }).catch()
        },
        // 非计划停机时间占比downtime
        downtimeTrend (noPlan, sumTimes) {
            const trend = echarts.init(document.getElementById('downtime'))
            const option = {
                title: {
                    text: '非计划停机时间占比',
                    left: '20',
                    textStyle: {
                        fontSize: '16'
                    }
                },
                legend: {
                    right: '20',
                    formatter: function (name) {
                        return name + ' (小时) '
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [
                    {
                        name: '非计划停机时间占比',
                        type: 'pie',
                        radius: [40, 60],
                        center: ['50%', '50%'],
                        roseType: 'radius',
                        startAngle: '340',
                        itemStyle: {
                            borderRadius: 8
                        },
                        data: [
                            {
                                value: sumTimes,
                                name: '总停机时长',
                                itemStyle: {
                                    color: '#4BC8FF'
                                }
                            },
                            {
                                value: noPlan,
                                name: '非计划停机',
                                itemStyle: {
                                    color: '#FF7575'
                                }
                            }
                        ]
                    }
                ]
            }
            trend.setOption(option)
            // 自动渲染echarts
            window.addEventListener('resize', () => {
                trend.resize()
            })
        },
        // 故障停机时间占比malDowntime
        malDowntimeTrend () {
            const trend = echarts.init(document.getElementById('malDowntime'))
            const option = {
                title: {
                    text: '故障停机时间占比',
                    left: '20',
                    textStyle: {
                        fontSize: '16'
                    }
                },
                legend: {
                    right: '20',
                    formatter: function (name) {
                        return name + ' (小时) '
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [
                    {
                        name: '故障停机时间占比',
                        type: 'pie',
                        radius: [40, 60],
                        center: ['50%', '50%'],
                        roseType: 'radius',
                        startAngle: '280',
                        itemStyle: {
                            borderRadius: 8
                        },
                        data: [
                            {
                                value: 60,
                                name: '电气',
                                itemStyle: {
                                    color: '#4BC8FF'
                                }
                            },
                            {
                                value: 30,
                                name: '机械',
                                itemStyle: {
                                    color: '#FF7575'
                                }
                            },
                            {
                                value: 10,
                                name: '工艺',
                                itemStyle: {
                                    color: '#46EED1'
                                }
                            }
                        ]
                    }
                ]
            }
            trend.setOption(option)
            // 自动渲染echarts
            window.addEventListener('resize', () => {
                trend.resize()
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/demo.scss';
</style>

import { httpPhm, httpEquipment, httpPhmView } from '@/api/request'
// import { httpPhmView } from '@/api/request'
// import {httpEquipment} from '@/api/request'
/* 获取设备状态总览 */
export function reqEqStatus (params) { return httpPhm({ url: '/monitor/getEquipmentStatus', method: 'get', params }) }
/** 趋势 */
export function reqEqConsumptionTrend (params) { return httpEquipment({ url: '/sporadicshipments/cost/trend', method: 'get', params }) }

export function reqCamerasReportData (params) { return httpPhmView({ url: '/alarm/getCamerasReportData', method: 'get', params }) }

/** 非计划停机时间 */
// /accidetManage/no/plan
export function reqNoPlanManage (params) { return httpEquipment({ url: '/accidetManage/no/plan', method: 'get', params }) }

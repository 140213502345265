import * as common from './common'
import * as validate from './validate.js'
import * as dict from './dict.js'
import tools from './tools'

/* 注册到vue全局 */
export default {
    ...common,
    ...validate,
    ...dict,
    tools
}

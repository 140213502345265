import Home from '@/layout/home'

export default [
    {
        path: '/metadataManagement',
        name: 'metadataManagement',
        title: '元数据管理',
        component: Home,
        meta: { menu: true },
        children: [{
            path: 'ivyobject',
            name: 'ivyobject',
            title: '对象管理',
            meta: { menu: true },
            component: () => import('@/views/metadataManagement/ivyobject/ivyobject.vue')
        },
        {
            path: 'ivyobjectAE',
            name: 'ivyobjectAE',
            title: '编辑新增对象',
            component: () => import('@/views/metadataManagement/ivyobject/ivyobjectAE.vue')
        },
        {
            path: 'ivyobjectD',
            name: 'ivyobjectD',
            title: '详情对象',
            component: () => import('@/views/metadataManagement/ivyobject/ivyobjectD.vue')
        },
        ]
    }
]

<template>
    <div style="margin-right:20px;">
        <div class="cardStyle" id="sparePartsC" style="height:410px;"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import { reqEqConsumptionTrend } from '@/api/globalKanban/globalKanban.js'

export default {
    name: 'sparePartsC', // 备件消耗趋势
    data () {
        return {
        }
    },
    mounted () {
        this.sparePartsCTrend()
        this.getData()
    },
    methods: {
        getData () {
            reqEqConsumptionTrend().then((res) => {
                // const xData = res[0].month
                // const yData = res[1].const
                const xData = res.data.month
                const yData = res.data.cost
                this.sparePartsCTrend(xData, yData)
            }).catch()
        },
        // 备件消耗费用趋势
        sparePartsCTrend (xData, yData) {
            const trend = echarts.init(document.getElementById('sparePartsC'))
            const option = {
                title: {
                    text: '备件消耗趋势图',
                    left: '20',
                    textStyle: {
                        fontSize: '16'
                    }
                },
                legend: {
                    icon: 'roundRect',
                    right: '20',
                },
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                            color: '#E9E9E9'
                        }
                    },
                },
                series: [{
                    name: '2020',
                    data: yData,
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#198FD8'
                    }
                },
                {
                    name: '2021',
                    data: [220, 732, 701, 734, 1090, 1130],
                    type: 'line',
                    smooth: true,
                    showSymbol: false,
                    itemStyle: {
                        color: '#33DDE6'
                    }
                }]
            }
            trend.setOption(option)
            // 自动渲染echarts
            window.addEventListener('resize', () => {
                trend.resize()
            })
        },
    },

}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/demo.scss';
</style>

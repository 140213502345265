<template>
    <div id="login">
        <div class="warp">
            <div id="logo">
                <img v-if="logo === 'ivy'" class="name" src="../../assets/image/name.png" alt="">
                <span v-else-if="logo === 'nj'">nanjing</span>
            </div>
            <div class="logWrap">
            <div class="logCenter">
                <div class="logTop">
                    <div id="logTit">
                        <h2>
                            用户登录
                        </h2>
                        <p>设备智能管理与服务平台</p>
                    </div>
                </div>
                <div class="logMain">
                    <div id="logForm">
                        <el-form ref="loginForm" :model="loginForm" :rules="logRules">
                            <el-form-item  prop="username">
                                <el-input class="username" v-model="loginForm.username" placeholder="请输入您的账号">
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input class="password" v-model="loginForm.password" :type="inputType" placeholder="请输入您的密码" @keyup.enter.native="confirmLogin">
                                </el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-checkbox style="color:#2153C6" v-model="checked">
                                    记住密码
                                </el-checkbox>
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="confirmLogin">
                                    登录
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import { login } from '@/api/login/login'
import { mapActions } from 'vuex'
export default {
    /* 登录页面 */
    name: 'Login',

    data () {
        return {
            logo: '',
            loginForm: {
                username: '',
                password: ''
            },
            logRules: {
                username: [
                    { required: true, message: '请输入账号！', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码！', trigger: 'blur' }
                ]
            },
            inputType: 'password',
            pwVisible: { pwShow: true, pwHide: false },
            checked: false
        }
    },

    created () {
        Cookies.set('currentAppId', '')
        this.setNamePassword()
        this.checkDomain()
    },

    methods: {
        ...mapActions(['actionAppsData']),

        checkDomain () {
            const logo = 'ivy'
            // const url = location.href
            this.logo = logo
        },

        // 密码的显示与隐藏
        changePwVisible () {
            if (this.inputType === 'text') {
                this.inputType = 'password'
                this.pwVisible = { pwShow: true, pwHide: false }
            } else {
                this.inputType = 'text'
                this.pwVisible = { pwShow: false, pwHide: true }
            }
        },

        setNamePassword () {
            const remember = Cookies.get('login')
            if (remember) {
                const np = decodeURIComponent(remember).split('&')
                this.loginForm.password = np[0]
                this.loginForm.username = np[1]
                this.checked = true
            }
        },

        confirmLogin (formName) {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    const { loginForm: { username, password }, checked } = this
                    Cookies.set('Authorization', 'Basic aXZ5Oml2eQ==')
                    const form = new FormData()
                    form.append('username', username)
                    form.append('password', password)
                    login(form)
                        .then((result) => {
                            const { tenant_admin: authTYPE, access_token: token, token_type: tokenType, user_id: userId } = result
                            Cookies.set('token', token)
                            Cookies.set('tokenType', tokenType)
                            Cookies.set('Authorization', `${tokenType} ${token}`)
                            Cookies.set('authTYPE', authTYPE)
                            Cookies.set('userId', userId)
                            this.actionAppsData()
                                .then((result) => {
                                    this.$router.push({ path: '/' })
                                })
                            if (checked) {
                                Cookies.set('login', encodeURIComponent(`${password}&${username}`))
                            } else {
                                Cookies.set('login', '')
                            }
                        })
                } else {
                    this.$message({
                        type: 'error',
                        message: '登录失败！'
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
// @import "~@/styles/login/login.scss";
@import "~@/styles/login/login1.scss";
</style>

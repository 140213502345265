<template>
  <div class="flex rowColumn">
    <devStatusBlock></devStatusBlock>
    <div class="flex" style="margin: 0 15px 15px">
      <!-- left -->
      <div class="left flex2 flex rowColumn">
        <!-- top -->
        <div class="flex">
          <div class="cardBox flex2 flexGrow1">
            <ul class="flex cardStyle justifySa">
              <li>
                <img class="mb15" src="../../assets/image/gaojing.png" alt="">
                <div class="font12">{{alarmData.alarmTotal}}</div>
                <!-- <div class="font26 mb15">1076<span class="font12">H</span> </div> -->
                <div class="font12">告警总量</div>
                <!-- <div class="font26 mb15">854<span class="font12">H</span> </div> -->
                <!-- <div>MTBF</div> -->
              </li>
              <li>
                <img class="mb15" src="../../assets/image/yichuli.png" alt="">
                <div class="font12">{{alarmData.alarmResolved}}</div>
                <!-- <div class="font26 mb15">1076<span class="font12">H</span> </div> -->
                <div class="font12">已处理</div>
                <!-- <div class="font26 mb15">854<span class="font12">H</span> </div> -->
                <!-- <div>MTBF</div> -->
              </li>
              <li>
                <img class="mb15" src="../../assets/image/jianqu.png" alt="">
                <div class="font12">{{alarmData.alarmUnresolved}}</div>
                <!-- <div class="font26 mb15">1076<span class="font12">H</span> </div> -->
                <div class="font12">未处理</div>
                <!-- <div class="font26 mb15">854<span class="font12">H</span> </div> -->
                <!-- <div>MTBF</div> -->
              </li>
              <!-- <li>
                <img class="mb15" src="../../assets/image/dianjian@2x.png" alt="">
                <div class="font12">2021年</div>
                <div class="font26 mb15">1076<span class="font12">H</span> </div>
                <div class="font12">2020年</div>
                <div class="font26 mb15">854<span class="font12">H</span> </div>
                <div>MTBF</div>
              </li> -->
            </ul>
          </div>

          <div class="flex1 alarmStatisticsBox cardStyle">
            <ul class=" flex rowColumn justifySa">
              <li class="fontW">
                告警级别统计
              </li>
              <li>
                <p>紧急</p>
                <el-progress :percentage="15" text-inside :stroke-width="16" color="#FF7575"></el-progress>
              </li>
              <li>
                <p>重要</p>
                <el-progress :percentage="20" text-inside :stroke-width="16" color="#198FD8"></el-progress>
              </li>
              <li>
                <p>一般</p>
                <el-progress :percentage="65" text-inside :stroke-width="16" color="#33E1AD"></el-progress>
              </li>
            </ul>
          </div>

        </div>
        <!-- bottom -->
        <div class="leftBottom flex rowColumn">
          <!-- <div class="flex justifySa leftBottom-card">
                            <div class="flexGrow1 flex alignC justifyC lbBox cardStyle">
                                <div><img src="../../../assets/image/gaojing@2x.png" alt=""></div>
                                <div>告警总量</div>
                                <div>1319</div>
                            </div>
                            <div class="flexGrow1 flex alignC justifyC lbBox cardStyle">
                                <div><img src="../../../assets/image/yichuli@2x.png" alt=""></div>
                                <div>已处理</div>
                                <div>256</div>
                            </div>
                            <div class="flexGrow1 flex alignC justifyC lbBox cardStyle">
                                <div><img src="../../../assets/image/weichuli@2x.png" alt=""></div>
                                <div>未处理</div>
                                <div>1063</div>
                            </div>
                        </div> -->
          <sparePartsC />
        </div>
      </div>
      <!-- right -->
      <div class="right flex1 flex rowColumn">
        <sevenDayAlarmTrend />
        <downtimePercentage />
      </div>
    </div>
  </div>
</template>

<script>
import { reqCamerasReportData } from '@/api/globalKanban/globalKanban.js'
import devStatusBlock from './devStatusBlock.vue'
import sevenDayAlarmTrend from './sevenDayAlarmTrend.vue'
import downtimePercentage from './downtimePercentage.vue'
import sparePartsC from './sparePartsC.vue'
export default {
    name: 'globalKanban', // 全局看板
    components: {
    // 头部设备状态
        devStatusBlock: devStatusBlock,
        // 近7天告警
        sevenDayAlarmTrend: sevenDayAlarmTrend,
        // 故障停机占比、非计划停机占比
        downtimePercentage: downtimePercentage,
        // 备件消耗趋势
        sparePartsC: sparePartsC,
    },
    data () {
        return {
            alarmData: {},
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            reqCamerasReportData().then(res => {
                // console.log(res.data)
                this.alarmData = res.data
            }).catch()
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/demo.scss";
</style>

export default {
    namespaced: true,

    state: {
        /* 题库分类 */
        typeTree: [],
    },

    mutations: {
        TYPE_TREE (state, data) {
            state.typeTree = data
        }
    },

    actions: {
        actionSetTypeTree ({ commit }, data) {
            commit('TYPE_TREE', data)
        }
    }
}

import Home from '@/layout/home'

export default [
    {
        path: '/system',
        name: 'system',
        title: '系统管理',
        component: Home,
        meta: { menu: true },
        children: [{
            path: 'organization',
            name: 'organization',
            title: '组织管理',
            meta: { menu: true },
            component: () => import('@/views/system/organization/organization.vue')
        },
        {
            path: 'dictionary',
            name: 'dictionary',
            title: '字典管理',
            meta: { menu: true },
            component: () => import('@/views/system/dictionary/dictionary.vue')
        },
        {
            path: 'dictionaryItem',
            name: 'dictionaryItem',
            title: '字典项',
            component: () => import('@/views/system/dictionary/dictionaryItem.vue')
        },
        {
            path: 'user',
            name: 'user',
            title: '用户管理',
            meta: { menu: true },
            component: () => import('@/views/system/user/user.vue')
        },
        {
            path: 'role',
            name: 'role',
            title: '角色管理',
            meta: { menu: true },
            component: () => import('@/views/system/role/role.vue')
        }
        ]
    }
]

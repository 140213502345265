<template>
    <div>
        <template v-for="item in currentTree" :index="item.pageComponent">
            <el-submenu v-if="item.children.length" :key="item.pageComponent" :index="item.pageComponent">
                <template slot="title">
                    <i :class="item.icon"></i>
                    <span>{{ item.label }}</span>
                </template>
                <Menu :key="item.pageComponent" :menuTree="item.children" />
            </el-submenu>
            <el-menu-item
                v-else
                :index="item.pageComponent"
                :key="item.pageComponent"
                @click="routerSkip(item)"
            >
                {{ item.label }}
             </el-menu-item>
        </template>
    </div>
</template>

<script>
export default {
    /* 菜单组件 */
    name: 'Menu',

    props: {
        menuTree: {
            type: Array,
            default: () => []
        }
    },

    data () {
        return {
            currentTree: []
        }
    },

    watch: {
        menuTree: {
            immediate: true,
            handler (arr) {
                const recursionFn = (array) => {
                    const result = []
                    array.forEach(item => {
                        if (item.displayTag) {
                            if (item.children.length) item.children = recursionFn(item.children)
                            result.push(item)
                        }
                    })
                    return result
                }
                this.currentTree = recursionFn(arr)
            }
        }
    },

    methods: {
        routerSkip (info) {
            if (info.externalUrl) {
                this.$router.push({ name: 'externalLink', query: { url: info.externalUrl } })
            } else {
                this.$router.push({ name: info.pageComponent })
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>

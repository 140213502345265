<template>
    <div class="index_head_part">
        <div class="index_head_part_info">
            <div class="menu_fold_icon">
                <div class="logo" @click="goIndex">
                    <div v-show="!menuCollapse">
                        <img src="../assets/image/logo.jpg"/>
                        <p>设备智能管理与服务平台</p>
                    </div>
                    <div v-show="menuCollapse"><img src="../assets/image/logo.jpg"/></div>
                </div>
                <!-- 所有应用 -->
                <ul class="applications">
                    <li v-for="item in appData" :class="{ 'app-selected': appId === item.id }" :key="item.id" @click="handleChangeApp(item.id)">
                        <span>
                            {{ item.name }}
                        </span>
                    </li>
                </ul>
            </div>
            <div class="user" style="position: relative;">
                <span style="cursor: pointer;" v-show="show" @click="toQuestion"><img src="../assets/image/lian.png" style="width: 15px;height: 15px;margin-right: 8px;cursor: pointer;">联系产品</span>
                <img v-show="readShow" src="../assets/image/rej.png" style="width: 10px;height: 10px;position: absolute;margin-left:37px;margin-top:7px">
                <el-popover
                    placement="bottom-end"
                    width="280"
                    @show="getList"
                    trigger="click">
                    <img style="width: 15px;height: 15px;cursor: pointer;" slot="reference" src="../assets/image/message.png" alt="-_-">
                    <div class="warp">
                         <div class="notice-header">
                        <span style="color: rgb(85,85,85); font-size: 18px; font-weight: 600; display: inline;">通知中心</span>
                        <el-button style="float: right; padding: 3px 0; color: rgb(127,127,127)" type="text">全部忽略</el-button>
                      </div>
                      <ul v-if="noticeList.length>0" v-infinite-scroll="load" style="overflow:auto;margin-top:30px;" class="notice-list" >
                        <li @click="toDetail(item)" v-for="(item,index) in noticeList" :key="index" class="notice-item">
                          {{item.title}}
                        </li>
                      </ul>
                      <div v-else style="height: 80px; line-height: 80px; font-size: 18px; text-align:center">暂无消息</div>
                      <div class="new-btn" @click="toNotice" style="width: 300px; cursor: pointer; margin-top:8px; border-top:1px solid rgb(215,215,215); font-size:18px; height: 30px; text-align: center; line-hight:30px">查看全部</div>
                    </div>
                  </el-popover>
                <span>{{ username }}</span>
                <span @click="handleLogout" style="cursor: pointer;"><i class="el-icon-switch-button"></i></span>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapState, mapActions } from 'vuex'

import { reqLoginOut } from '@/api/login/login'
import { reqNewsList, reqListCount } from '@/api/newsCenter/newsCenter.js'

export default {
    data () {
        return {
            username: '',
            appId: '',
            noticeList: [],
            dialogTableVisible: false,
            id: '',
            show: true,
            appData: [],
            tongList: [],
            count: 0,
            current: 1,
            size: 20,
            total: 0,
            readShow: false
        }
    },
    watch: {
        falg: {
            if (falg) {
                this.show = true
            }
        }
    },
    created () {
        const { user } = this
        if (user.sysUser) {
            this.username = user.sysUser.name
        }

        const appId = Cookies.get('currentAppId')
        if (appId) {
            this.appId = Number(appId)
        }
        this.applicationsData.sort(this.compare('orderNum'))
        // this.appData = this.applicationsData.filter((item) => {
        //     return item.menuTree.length > 0
        // })
        this.applicationsData.forEach((v, index) => {
            if (v.thirdAppTag === 1) {
                if (v.menuTree.length === 0) {
                    this.applicationsData.splice(index, 1)
                }
            }
        })
        this.appData = this.applicationsData.sort((a, b) => (a.orderNum - b.orderNum))
        this.getListCount()
    },
    computed: {
        ...mapState(['user', 'menuCollapse', 'applicationsData', 'newsList'])
    },

    methods: {
        ...mapActions(['actionToggleMenu', 'actionCurrentAppSet', 'acitonNewsList']),

        /* 退出登录 */
        handleLogout () {
            reqLoginOut()
                .then(() => {
                    this.$utils.websocketFn().close()
                    Cookies.set('Authorization', '')
                    window.sessionStorage.removeItem('newsList')
                    window.sessionStorage.setItem('wsClientId', '')
                    this.$router.push({
                        path: '/login'
                    })
                })
        },
        getListCount () {
            reqListCount().then((res) => {
                if (res.data > 0) {
                    this.readShow = true
                } else {
                    this.readShow = false
                }
            })
        },
        handleMenuClick (name) {
            if (name === 'modifyPassword') {
                this.$router.push({
                    name: 'modifyPassword'
                })
            }
        },

        handleChangeApp (id) {
            Cookies.set('currentAppId', id)
            this.appId = id
            this.$router.push({ path: '/' })
            this.actionCurrentAppSet(id)
            // 调用tags组件方法
            this.$emit('save')
            let code = ''
            this.applicationsData.forEach(i => {
                if (i.id === id) {
                    code = i.code
                }
            })
            this.$emit('appChange', code, id)
        },

        goIndex () {
            this.$router.push({
                path: '/'
            })
        },
        toQuestion () {
            this.$emit('change')
        },
        getList () {
            // 请求消息数据
            reqNewsList({ current: this.current, size: this.size }).then((res) => {
                const { data } = res
                this.noticeList = [...this.noticeList, ...data.records]
                this.total = data.total
                this.noticeList.forEach((item) => {
                    if (item.readStatus === 0) {
                        this.tongList.push(item)
                    }
                })
                if (this.tongList.length > 0) {
                    this.count = this.tongList.length - 1
                } else {
                    this.count = 0
                }
            })
        },
        load () {
            if (this.current * this.size < this.total) {
                this.current = this.current + 1
                this.getList()
            }
        },
        // 查看全部消息
        toNotice () {
            // 跳转路由
            // this.$router.push({ name: 'allNews' })
            // this.$router.push({ name: 'allsNews' })
            this.$emit('news')
        },
        // 查看一条消息
        toDetail (item) {
            // this.$emit('newsDetail', 2, item, this.noticeList, item.id)
            // this.$router.push({ name: 'allsNews', params: { biaoji: 2, obj: item, list: this.noticeList, id: item.id } })
        },
        close () {
            this.dialogTableVisible = false
            // Cookies.
            // localStorage.removeItem('id')
        },
        compare (property) {
            return function (a, b) {
                var value1 = a[property]
                var value2 = b[property]
                return value1 - value2
            }
        }
    }
}
</script>

<style scoped lang="scss">
// .index_head_part .user span {
//     display: inline-block;
//     padding: 0 15px;
//     height: 20px;
//     line-height: 20px;
//     border-right: 1px solid rgba(255, 255, 255,)
// }
.el-popover{
  // height: 274px;
  // overflow: auto;
  position: relative;
}
.warp {
  height: 274px;
  overflow: auto;
}
.notice-header {
  width: 300px;
  height: 30px;
  background-color: rgb(244,244,244);
  line-height: 30px;
  box-shadow: 0 4px 0 rgb(242,242,242);
   position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}

#readShow{
  position: relative;
  left: 0;
  top: 0;
}
 .new-btn{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgb(244,244,244);
  }
.notice-list {
  margin-top: 30px;
  .notice-item {
    display: flex;
    align-items: center;
    height: 50px;
    word-wrap: break-word;
    border-bottom: 1px solid rgb(215,215,215);
  }
}
// .index_head_part .user span .el-popover__reference-wrapper {
//     padding-right: 0!important;
//     padding-left: 0;
// }
</style>
